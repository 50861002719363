<template>

    <div class="statistics-empty">

        <img class="hidden-xs-down" src="@/assets/statistics-empty-graphic-desktop.png" srcset="@/assets/statistics-empty-graphic-desktop@2x.png 2x">
        <img class="hidden-sm-up" src="@/assets/statistics-empty-graphic-mobile.png" srcset="@/assets/statistics-empty-graphic-mobile@2x.png 2x">

        <div class="statistics-empty__hint">
            <img src="@/assets/statistics-empty-icon.svg">
            <h2>Keine Statistiken vorhanden</h2>
            <p class="small">{{ description }}</p>
        </div>

    </div>

</template>

<script>
    export default {
        name: 'StatisticsEmpty',
        props: {
            description: {
                type: String,
                default() { return "Die Saison steht in den Startlöchern, aber noch hat keines der Teams ein Spiel absolviert."; } 
            },
        }
    }
</script>


<style lang="scss">
    .statistics-empty {
        position: relative;
        margin-top: 12px;

        @media (min-width: $breakpoint-sm) {
            margin-top: 24px;
        }

        .section & {
            margin-top: 0;
        }

        img {
            width: 100%;
        }

        &__hint {
            position: absolute;
            bottom: 50%;
            left: 0;
            width: 100%;
            transform: translateY(50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media (min-width: $breakpoint-sm) {
                bottom: 0;
                left: 0;
                width: 100%;
                transform: translateY(0);
            }

            img {
                width: 68px;
                height: 46px;
                margin-bottom: 16px;

                @media (min-width: $breakpoint-sm) {
                    width: 86px;
                    height: 60px;
                    margin-bottom: 32px;
                }
            }

            h2, p {
                margin: 0;
                max-width: 380px;
            }


            h2 {
                opacity: .64;
                margin-bottom: 8px;

                @media (min-width: $breakpoint-sm) {
                    margin-bottom: 5px;
                }
            }

            p {
                font-size: 14px;
                line-height: 20px;
                opacity: .40;

                @media (min-width: $breakpoint-sm) {
                    font-size: 17px;
                    line-height: 24px;
                }
            }
        }
    }
</style>