<template>

  <div class="rankingPreview">

    <!-- Dummy Teams -->

    <router-link :to="'/team/' + teamSlug(t) + '/' + competitionId" v-for="t in table" v-bind:key="t.fmpTeamId" :class="currentlyPlayingTeams.indexOf(t.fmpTeamId) != -1 ? 'rankingPreview__item rankingPreview__item--live' : 'rankingPreview__item'">
      <div class="rankingPreview__item__position">
        {{ t.position }}
      </div>
      <div class="rankingPreview__item__logo">
        <img :src="teamLogo(t)" :alt="'Logo ' + t.teamName">
      </div>
      <div class="rankingPreview__item__name">
        {{ t.teamName }}
      </div>
      <div class="rankingPreview__item__matches">
        <div class="rankingPreview__item__matches__item">
          {{ t.matchesWon }}
        </div>
        <div class="rankingPreview__item__matches__item">
          {{ t.matchesDraw }}
        </div>
        <div class="rankingPreview__item__matches__item">
          {{ t.matchesLost }}
        </div>
      </div>
      <div class="rankingPreview__item__form">
        <span v-for="o in _lastMatches(t).slice().reverse()" v-bind:key="o.fmpMatchId" :class="_matchResultClass(o, t)"></span>
        <span v-for="x in Array(5-_lastMatches(t).length).keys()" v-bind:key="x" class="placeholder"></span>
      </div>
      <div class="rankingPreview__item__points">
        {{ t.points }}
      </div>
    </router-link>

    <div @click="showAllTeams" class="rankingPreview__button">
      Alle Teams anzeigen
    </div>

  </div>

</template>

<script>
import {teamLogoUrl, matchResultClass, slugify} from '@/hbf-helper';

export default {
    name: 'RankingPreview',
    mounted() {
        this.init();
    },
    data() {
        return {
            table: null,
            liveData: null,
        }
    },
    methods: {
        init() {
            this.$store.dispatch("fetchLiveData").then( () => {
                this.liveData = this.$store.getters.getLiveData;
            });
            this.$store.dispatch("fetchData",  {filename: "tables/table_" + this.seasonId + ".json"}).then( (res) => {
                this.table = res.table;
            });
        },
        showAllTeams() {
            document.querySelectorAll('.rankingPreview__item').forEach(function(el) {
                el.style.display="flex";
            });
            document.querySelectorAll('.rankingPreview__button').forEach(function(el) {
                el.style.display="none";
            });
        },
        teamLogo(team) {
            return teamLogoUrl(team, 40, this.baseUrl, this.seasonId);
        },
        _matchResultClass(o, team) {
            return matchResultClass(o ,team);
        },
        _lastMatches(t) {
            return t.lastMatches || [];
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        }
    },
    props: {
        competitionId: {
            type: Number,
            default() { return -1; } 
        },
        seasonId: {
            type: Number,
            default() { return -1; } 
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        },
        currentlyPlayingTeams() {
            if (this.liveData) {
                let matches = this.liveData.filter(m => m.fmpSeasonId == this.seasonId && m.match_time > 0 && m.status != "closed");
                return matches.flatMap(m => [m.fmpTeamHomeId, m.fmpTeamAwayId]);
            }
            return [];
        }
    }
}
</script>

<style lang="scss">
  .rankingPreview {
    background: $colorWhite;
    box-shadow: 0 2px 8px 0 rgba($colorBlack, .02), 0 0 0 1px rgba($colorBlack, .06);
    border-radius: 12px;
    padding: 8px 16px;
    overflow: hidden;

    a {
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -16px;
        height: 100%;
        width: calc(100% + 32px);
        background: rgba($colorBlack, .02);
        opacity: 0;
        transition: all .2s ease-in-out;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      color: $colorBlack;
      padding: 5px;

      &:nth-child(n+6) {
        display: none;

        @media (min-width: $breakpoint-sm) {
          display: flex;
        }
      }

      &--highlight {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -16px;
          height: 100%;
          width: calc(100% + 32px);
          background: rgba($colorBlack, .02);
        }
      }

      &__position {
        width: 24px;
        text-align: center;
        margin-left: -12px;
        padding-right: 4px;
        opacity: .32;
      }

      &__logo {
        display: flex;

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          object-position: center center;
        }

        .rankingPreview__item--live & {
          position: relative;
          padding-right: 16px;

          @keyframes liveAnimation-dot {
            0% {
              transform: scale(.9);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(.9);
            }
          }

          @keyframes liveAnimation-circle {
            0% {
              transform: scale(0);
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: scale(1.5);
            }
          }

          &:before {
            content: '';
            position: absolute;
            width: 4px;
            height: 4px;
            right: 0;
            top: calc(50% - 2px);
            background: $colorLive;
            border-radius: 2px;
            animation: liveAnimation-dot 2s infinite;
          }

          &:after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            right: -3px;
            top: calc(50% - 5px);
            box-shadow: inset 0 0 0 1px $colorLive;
            border-radius: 5px;
            background: none;
            animation: liveAnimation-circle 2s infinite;
          }
        }
      }

      &__name {
        flex: 1;
        margin-left: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .rankingPreview__item--live & {
          color: $colorLive;
        }
      }

      &__matches {
        display: none;

        @media (min-width: $breakpoint-md) {
          display: flex;
          margin-right: 8px;
        }

        &__item {
          width: 24px;
          opacity: .48;
        }
      }

      &__form {
        display: flex;
        padding-left: 4px;

        span {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: 0 2px;

          &.win {
            background: $colorSuccess;
          }

          &.draw {
            background: rgba($colorBlack, .32);
          }

          &.lose {
            background: $colorDanger;
          }

          &.placeholder {
            box-shadow: inset 0 0 0 1px rgba($colorBlack, .12);
          }
        }
      }

      &__points {
        width: 40px;
        text-align: center;
        margin: 0 -8px 0 8px;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      margin-top: 4px;
      font-size: 11px;
      font-weight: 800;
      text-transform: uppercase;
      color: rgba($colorBlack, .48);
      border-radius: 4px;
      transition: all .2s ease-in-out;

      @media (min-width: $breakpoint-sm) {
        display: none;
      }

      &:after {
        content: url('@/assets/ic_ranking_expand.svg');
        transform: translateY(1px);
        margin-left: 8px;
        opacity: .48;
        transition: all .2s ease-in-out;
      }

      &:hover {
        cursor: pointer;
        background: rgba($colorBlack, .04);
        color: rgba($colorBlack, .64);

        &:after {
          opacity: .64;
        }
      }
    }
  }
</style>
