<template>
    <div>
        <HomeSlider v-if="homePageInfo" :slides="homePageInfo.featured_articles"/>

        <div class="container">
            <div class="grid">

            <MobileAd adType="Mobile_Ad"></MobileAd>
            <DisplayAd adType="Billboard"></DisplayAd>

            <div class="col-12" v-if="featuredCompetition">
                <div class="section" >
                    <div class="section__title">
                        <h2>{{ featuredCompetition.name }}</h2>
                    </div>

                    <HomeMatches :competition="featuredCompetition" :seasonId="featuredCompetition.fmpSeasonId" />
                </div>
            </div>

            <!-- Matchkalender -->
            <div class="col-12">
                <div class="section">
                <div class="section__title">
                    <h2 class="hidden-xs-up">Spiele</h2>
                    <h2 class="hidden-xs-down">Aktuelle Spiele</h2>
                    <ul class="section__title__tabs">
                    <li :class="activeCalendarView == 1 ? 'is-active' : ''" @click="setActiveCalendar(1)">
                        <span class="hidden-xs-up">1. BL</span>
                        <span class="hidden-xxs">1. Bundesliga</span>
                    </li>
                    <li :class="activeCalendarView == 2 ? 'is-active' : ''" @click="setActiveCalendar(2)">
                        <span class="hidden-xs-up">2. BL</span>
                        <span class="hidden-xxs">2. Bundesliga</span>
                    </li>
                    <li :class="activeCalendarView == 3 ? 'is-active' : ''" @click="setActiveCalendar(3)">
                        <span class="hidden-xs-up">Pokal</span>
                        <span class="hidden-xxs">DHB-Pokal</span>
                    </li>
                    </ul>
                    <router-link :to="'/liga/' + leagueSlugFromId(activeCalendarView) + '/spielplan'" class="section__title__more">
                    <span class="hidden-xs-down">Alle Spiele</span>
                    <span class="hidden-xs-up">Alle</span>
                    </router-link>
                </div>
                <HomeMatches :ref="'matchCalendar'+competition.id" v-show="activeCalendarView == competition.id" v-for="competition in homePageCompetitions" v-bind:key="competition.id" :competition="competition" :seasonId="competition.fmpSeasonId" @mostRecentMatch="activateMostRecentMatchesTab"/>
                </div>
            </div>

            <!-- Tabelle (Mobile) -->
            <div class="col-12 hidden-sm-up">
                <div class="section">
                    <div class="section__title">
                        <h2>Tabelle</h2>
                        <ul class="section__title__tabs">
                            <li :class="activeRankingView == 1 ? 'is-active' : ''" @click="activeRankingView = 1">
                                <span class="hidden-xs-up">1. BL</span>
                                <span class="hidden-xxs">1. Bundesliga</span>
                            </li>
                            <li :class="activeRankingView == 2 ? 'is-active' : ''" @click="activeRankingView = 2">
                                <span class="hidden-xs-up">2. BL</span>
                                <span class="hidden-xxs">2. Bundesliga</span>
                            </li>
                        </ul>
                        <router-link :to="'/liga/' + leagueSlugFromId(activeRankingView) + '/tabelle'" class="section__title__more">
                            Zur Liga
                        </router-link>
                    </div>
                    <RankingPreview v-show="activeRankingView == competition.id" v-for="competition in allLeagues" v-bind:key="competition.id"  :competitionId="competition.id" :seasonId="competition.fmpSeasonId"/>
                </div>
            </div>

            <!-- Tabelle (Desktop) -->
            <div class="col-12 hidden-xs-down">
                <div class="grid">
                    <div v-for="competition in allLeagues" v-bind:key="competition.id" class="col-6">
                        <div class="section" id="leagueranking">
                            <div class="section__title">
                                <h2>{{ competition.name }}</h2>
                                <router-link :to="'/liga/' + leagueSlugFromId(competition.id) + '/tabelle'" class="section__title__more">
                                    Zur Liga
                                </router-link>
                            </div>
                            <RankingPreview :competitionId="competition.id" :seasonId="competition.fmpSeasonId" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Hinweis für Punktabzug unterhalb von Tabelle (Desktop)-->
            <!-- <div class="col-12" v-if="activeRankingView == 2 || clientWidth() > 768">
                <div class="grid justify-end">
                    <div class="col-xs-12 col-sm-6 col-bleed-y">
                        <div class="rankinghint">
                            Abzug von 4 Pluspunkten SV Werder Bremen wegen Verstoß gegen die Lizenzierungsrichtlinien.
                            <br/>Das Spiel Lintfort - Regensburg wurde aufgrund des Einsatzes einer nicht spielberechtigten Spielerin mit 0:2 Punkten und 0:0 Toren gewertet.
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- News -->
            <div class="col-12">
                <div class="section">
                <div class="section__title">
                    <h2>News</h2>
                    <router-link to="/news" class="section__title__more">
                    Mehr News
                    </router-link>
                </div>
                <HomeNews/>
                </div>
            </div>

            <MobileAd adType="Mobile_MR"></MobileAd>
            <DisplayAd adType="QualityFooter"></DisplayAd>

            <div class="col-12">
                <div class="section">
                <div class="section__title">
                    <h2>Statistiken</h2>
                    <ul class="section__title__tabs">
                    <li :class="activeStatisticsView == 1 ? 'is-active' : ''" @click="activeStatisticsView = 1">
                        <span class="hidden-xs-up">1. BL</span>
                        <span class="hidden-xxs">1. Bundesliga</span>
                    </li>
                    <li :class="activeStatisticsView == 2 ? 'is-active' : ''" @click="activeStatisticsView = 2">
                        <span class="hidden-xs-up">2. BL</span>
                        <span class="hidden-xxs">2. Bundesliga</span>
                    </li>
                    </ul>
                    <router-link :to="'/liga/' + leagueSlugFromId(activeStatisticsView) + '/statistiken'" class="section__title__more">
                    Details
                    </router-link>
                </div>
                <HomeStatistics v-show="activeStatisticsView == competition.id" v-for="competition in allLeagues" v-bind:key="-competition.id" :seasonId="competition.fmpSeasonId" :competitionId="competition.id"/>
                </div>
            </div>

            </div>
        </div>
    </div>
</template>

<script>
import HomeSlider from '@/components/HomeSlider.vue'
import HomeMatches from '@/components/HomeMatches.vue'
import HomeNews from '@/components/HomeNews.vue'
import HomeStatistics from '@/components/HomeStatistics.vue'
import RankingPreview from '@/components/RankingPreview.vue'
import {slugify} from '@/hbf-helper';
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import MobileAd from "@/components/MobileAd.vue";
import DisplayAd from "@/components/DisplayAd.vue";

export default {
    name: 'HomeView',
    setup() {
        const siteData = reactive({
            title: 'Handball Bundesliga Frauen',
            description: '',
        });

        useHead({
            // Can be static or computed
            title: computed(() => siteData.title),
            meta: [
                {
                    name: `description`,
                    content: computed(() => siteData.description),
                },
            ],
        });

        return {
            siteData
        }
    },
    components: {
        HomeSlider, HomeMatches, HomeNews, HomeStatistics, RankingPreview, MobileAd, DisplayAd
    },
    data() {
        return {
            competitions: [],
            homePageInfo: null,
            activeCalendarCompetition: null,
            activeCalendarView: null,
            activeRankingView: null,
            activeStatisticsView: null,
            currentDayDiff: null,
            liveData: null,
        }
    },
    computed: {
        allLeagues() {
            if (this.competitions != null) {
                return this.competitions.filter(c => c.competitionType == 1);
            }
            return [];
        },
        homePageCompetitions() {
            if (this.competitions != null) {
                // TODO: should set an attribute in the CMS here to better keep track of active leagues,
                // but this will probably never change anyway
                return this.competitions.filter(c => c.id <= 3);
            }
            return [];
        },
        featuredCompetition() {
            if (this.homePageInfo && this.homePageInfo.featured_competition) {
                let fc = this.competitions.find(c => c.id == this.homePageInfo.featured_competition.id);
                return fc;
            }
            else {
                return null;
            }
        },
    },
    methods: {
        clientWidth() {
            return Math.max(
                document.body.scrollWidth,
                document.documentElement.scrollWidth,
                document.body.offsetWidth,
                document.documentElement.offsetWidth,
                document.documentElement.clientWidth
            );
        },
        setActiveCalendar(leagueId) {
            this.activeCalendarView = leagueId;
            /* call refresh() which toggles display of slider, so that start index will be picked up correctly */
            let hmc = this.$refs["matchCalendar"+leagueId][0];
            this.$nextTick( () => hmc.refresh() );
        },
        init() {
            window._hbf_season_override = {};
            var reqs = [];
            reqs.push(this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
                this.activeCalendarView = this.competitions[0].id;
                this.activeRankingView = this.competitions[0].id;
                this.activeStatisticsView = this.competitions[0].id;
            }));
            reqs.push(this.$store.dispatch("fetchHomePageInfo").then( (res) => {
                this.homePageInfo = res.items[0];
                this.siteData.description = this.homePageInfo.meta.search_description;
                this.siteData.title = this.homePageInfo.meta.seo_title;
            }));
            reqs.push(this.$store.dispatch("fetchLiveData").then( () => {
                this.liveData = this.$store.getters.getLiveData;
            }));

            Promise.all(reqs).then( () => this.chooseActiveCalendarView() );
        },
        chooseActiveCalendarView() {
            console.log("choose active calendar view");
            // override from CMS
            if (this.homePageInfo && this.homePageInfo.active_match_calendar_tab) {
                this.activeCalendarView = this.homePageInfo.active_match_calendar_tab.id;
                this.$nextTick(() => {this.$forceUpdate()});
                return;
            }
        },
        leagueSlugFromId(leagueId) {
            var league = this.competitions.find(l => l.id == leagueId);
            if (!league) { return leagueId; }
            if (parseInt(league.name) == league.id) {
                return slugify(league.name);
            }
            return league.id + "--" + slugify(league.name);
        },
        // display calendar tab with most recent matches
        activateMostRecentMatchesTab(dayDiff, competition) {
            if (this.homePageInfo && this.homePageInfo.active_match_calendar_tab) {
                return;
            }
            dayDiff = Math.abs(dayDiff);
            if (this.currentDayDiff == null || dayDiff < this.currentDayDiff) {
                this.currentDayDiff = dayDiff;
                this.activeCalendarView = competition.id;
                this.activeCalendarCompetition = competition;
            }
            /* for equally far away matches, check priority */
            else if ((dayDiff == this.currentDayDiff) && (this.activeCalendarCompetition != null && competition.priority > this.activeCalendarCompetition.priority)) {
                this.activeCalendarView = competition.id;
                this.activeCalendarCompetition = competition;
            }
        }
    },
    /* TODO: Nur ein Workaround - muss umgebaut werden á la SportlerPlus mit meta {} im router.js */
    mounted () {
        document.body.classList.add('home');
        this.init();
    },
    unmounted () {
        document.body.classList.remove('home');
    }
}
</script>

<style lang="scss" scoped>
    #leagueranking {
        display: flex;
        flex-direction: column;
        height: 100%;

        .rankingPreview {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: space-between;
        }
    }
</style>