<template>

  <div class="ranking">
    <div class="ranking__content">
      <table>
        <thead>
          <tr>
            <th>Mannschaft</th>
            <th>Sp</th>
            <th>Form</th>
            <th>Pkt</th>
            <th>TD</th>
            <th>+</th>
            <th>-</th>
            <th>G</th>
            <th>U</th>
            <th>V</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in selectedTable" v-bind:key="t.fmpTeamId" :class="highlightClasses(t)">
            <td>
              <div class="ranking__team">
                <div class="ranking__team__position">{{ t.position }}</div>
                <div class="ranking__team__logo">
                  <img :src="teamLogo(t)" :alt="'Logo ' + t.teamName">
                </div>
                <div class="ranking__team__name"><router-link :to="'/team/' + teamSlug(t) + '/' + competitionId">{{ t.teamName }}</router-link></div>
              </div>
            </td>
            <td>{{ t.matchesWon + t.matchesLost + t.matchesDraw }}</td>
            <td>
              <div v-if="t.lastMatches" class="ranking__form">
                <span v-for="o in t.lastMatches.slice().reverse()" v-bind:key="o.fmpMatchId" :class="_matchResultClass(o, t)"></span>
                <span v-for="x in Array(5-t.lastMatches.length).keys()" v-bind:key="x" class="placeholder"></span>
              </div>
              <div v-else>-</div>
            </td>
            <td>{{ t.points }}</td>
            <td>{{ t.goalsDiff }}</td>
            <td>{{ t.goalsFor }}</td>
            <td>{{ t.goalsAgainst }}</td>
            <td>{{ t.matchesWon }}</td>
            <td>{{ t.matchesDraw }}</td>
            <td>{{ t.matchesLost }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>
import { teamLogoUrl, matchResultClass, slugify } from '@/hbf-helper';

export default {
    name: 'RankingItem',
    mounted() {
        this.init();
    },
    data() {
        return {
            table: null,
            homeTable: null,
            awayTable: null,
            selectedVariant: "full",
            liveData: null,
            outcomes: [],
            availableHighlightColors: [
                "blue",
                "turquoise",
                "orange",
                "red"
            ]
        }
    },
    methods: {
        init() {
            this.$store.dispatch("fetchLiveData").then( () => {
                this.liveData = this.$store.getters.getLiveData;
            });

            this.$store.dispatch("fetchData",  {filename: "tables/table_" + this.seasonId + ".json"}).then( (res) => {
                this.table = res.table;
                this.homeTable = res.home;
                this.awayTable = res.away;

                this.$nextTick(function () {
                    this.$emit("highlightRankings", this.outcomes);
                });
            });
        },
        setTableVariant(v) {
            this.selectedVariant = v;
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        },
        teamLogo(team) {
            return teamLogoUrl(team, 40, this.baseUrl, this.seasonId);
        },
        _matchResultClass(o, t) {
            return matchResultClass(o, t);
        },
        replaceRankingInfoString(s) {
            if (s== "Relegation Play-Offs") { return "Relegation"; }
            if (s== "Relegation Play-Off") { return "Relegation"; }
            if (s== "Aufstiegsspiel") { return "Relegation"; }
            return s;
        },
        highlightClasses(p) {
            if (this.selectedVariant !== "full") { return ""; }
            var classList = "";

            if (p.currentOutcome) {
                let outcome_text = this.replaceRankingInfoString(p.currentOutcome);
                let existing_outcome = this.outcomes.find(el => el.outcome == outcome_text);
                if (existing_outcome) {
                    classList = existing_outcome.classes
                }
                else {
                    let ranking_color = this.availableHighlightColors[this.outcomes.length % this.availableHighlightColors.length];
                    var new_outcome = {
                        id: 1 + this.outcomes.length,
                        outcome: outcome_text,
                        color: ranking_color,
                        classes: "rankingHighlight rankingHighlight--" + ranking_color
                    }
                    this.outcomes.push(new_outcome);
                    classList = new_outcome.classes;
                }
            }

            if (this.currentlyPlayingTeams.indexOf(p.fmpTeamId) != -1) {
                classList = classList + " is-live";
            }

            return classList
        }
    },
    props: {
        competitionId: {
            type: Number,
            default() { return -1; } 
        },
        seasonId: {
            type: Number,
            default() { return -1; } 
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        },
        currentlyPlayingTeams() {
            if (this.liveData) {
                let matches = this.liveData.filter(m => m.fmpSeasonId == this.seasonId && m.match_time > 0 && m.status != "closed");
                return matches.flatMap(m => [m.fmpTeamHomeId, m.fmpTeamAwayId]);
            }
            return [];
        },
        selectedTable() {
            if (this.selectedVariant === "home") { return this.homeTable; }
            else if (this.selectedVariant === "away") { return this.awayTable; }
            else return this.table;
        }
    }
}
</script>

<style lang="scss">
.ranking {
  position: relative;
  background: $colorWhite;
  box-shadow: 0 2px 8px 0 rgba($colorBlack, .02), 0 0 0 1px rgba($colorBlack, .06);
  border-radius: 12px;
  padding: 8px 0;

  @media (min-width: $breakpoint-sm) {
    padding: 4px 0 8px;
  }

  .ranking__content {
    @media (max-width: 767px) {
      overflow-x: scroll;
      overflow-y: visible;
    }
  }

  table {
    table-layout: auto;
    width: 100%;
    border-radius: 12px;
    border-spacing: 0;
    padding: 0;
    box-shadow: none;
  }

  thead {
    tr {
      background: none !important;
    }
  }

  tr {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        width: 24px;
        height: 100%;
        background-image: linear-gradient(270deg, rgba($colorWhite, .24) 0%, rgba($colorWhite, 0) 100%);
        border-radius: 0 12px 12px 0;

        @media (min-width: $breakpoint-sm) {
          display: none;
        }
    }

    &:after {
      content: '';
      display: block;
      width: 12px;
    }

    @media (min-width: $breakpoint-sm) {
      &:nth-child(odd) {
        background: rgba($colorBlack, .02);

        td:first-child, th:first-child {
          background: none;
        }
      }
    }
  }

  td,
  th {
    text-align: center;
    margin: 0;
    padding: 0;
    min-width: 32px;
    box-shadow: none !important;
    
    &:first-child {
      position: sticky;
      left: 0;
      width: 100%;
      text-align: left;
      max-width: calc(100vw - 196px);
      background: $colorWhite;
      padding-left: 16px;
      padding-right: 8px;

      &:before {
        content: '';
        position: absolute;
        right: -16px;
        top: 0;
        width: 16px;
        height: 100%;
        background-image: linear-gradient(270deg, rgba($colorWhite, 0) 0%, rgba($colorWhite, 1) 100%);

        @media (min-width: $breakpoint-sm) {
          display: none;
        }
      }
    }

    &:nth-child(2) {
      padding-left: 8px;
    }
  }

  th {
    font-size: 10px;
    font-weight: 700;
    color: rgba($colorBlack, .32);
    padding: 0 0 4px;
    background: $colorWhite !important;

    @media (min-width: $breakpoint-sm) {
      font-size: 12px;
      padding: 9px 0 13px;
    }
  }

  td {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 700;
    color: rgba($colorBlack, .48);

    &:nth-child(4) {
      color: $colorBlack;
    }
  }

  &__team {
    display: flex;
    align-items: center;
    padding: 5px 0;

    a {
        text-decoration: none;
        color: $colorBlack;
    }

    @media (min-width: $breakpoint-sm) {
      padding: 9px 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 12px);
      left: -16px;
      width: 2px;
      height: 24px;

      .rankingHighlight--blue & {
        background: #247FED;
      }

      .rankingHighlight--turquoise & {
        background: #41D4ED;
      }

      .rankingHighlight--orange & {
        background: #EC8608;
      }

      .rankingHighlight--red & {
        background: #CF1542;
      }
    }

    .rankingHighlight & {
      position: relative;
    }

    &__position {
      flex-shrink: 0;
      width: 24px;
      margin-left: -8px;
      text-align: center;
      color: rgba($colorBlack, .32);
      margin-right: 4px;
    }

    &__logo {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin-right: 10px;

      @media screen and (max-width: 350px) {
        display: none;
      }

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        object-position: center center;
      }

      .is-live & {
        position: relative;
        padding-right: 16px;

        @keyframes liveAnimation-dot {
          0% {
            transform: scale(.9);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(.9);
          }
        }

        @keyframes liveAnimation-circle {
          0% {
            transform: scale(0);
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: scale(1.5);
          }
        }

        &:before {
          content: '';
          position: absolute;
          width: 4px;
          height: 4px;
          right: 0;
          top: calc(50% - 2px);
          background: $colorLive;
          border-radius: 2px;
          animation: liveAnimation-dot 2s infinite;
        }

        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          right: -3px;
          top: calc(50% - 5px);
          box-shadow: inset 0 0 0 1px $colorLive;
          border-radius: 5px;
          background: none;
          animation: liveAnimation-circle 2s infinite;
        }
      }
    }

    &__name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $colorBlack;

      @media (min-width: $breakpoint-md) {
        font-size: 16px;
      }

      .is-live & {
        color: $colorLive;

        a {
          color: $colorLive;
        }
      }
    }
  }

  &__form {
    display: flex;
    padding: 0 6px;

    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      margin: 0 2px;

      &.win {
        background: $colorSuccess;
      }

      &.draw {
        background: rgba($colorBlack, .32);
      }

      &.lose {
        background: $colorDanger;
      }

      &.placeholder {
        box-shadow: inset 0 0 0 1px rgba($colorBlack, .12);
      }
    }
  }
}
</style>
