<template>
  <div v-if="dataReady">
    
    <!-- MOBILE -->
    <div class="slider hidden-sm-up">
      <Splide :options="options" :slides="abbrevSchedule">
        <SplideSlide v-for="m in abbrevSchedule" v-bind:key="m.fmpMatchId" >
          <MatchCard :matchInfo="m" :teams="detailData.teams" :competition="competition"/>
        </SplideSlide>
      </Splide>
    </div>

    <!-- Desktop -->
    <div class="grid hidden-xs-down">
      <div v-for="m in matchList" v-bind:key="m.fmpMatchId" class="col-md-4 col-sm-6">
        <MatchCard :matchInfo="m" :teams="detailData.teams"  :competition="competition"/>
      </div>
    </div>

  </div>

</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import MatchCard from '@/components/MatchCard.vue';
import {mostRecentMatches} from '@/hbf-helper';
import moment from 'moment';

export default {
    name: 'HomeMatches',
    props: {
        competition: {
            type: Object,
            default() { return {}; } 
        },
        seasonId: {
            type: Number,
            default() { return -1; } 
        }
    },
    components: {
        Splide,
        SplideSlide,
        MatchCard
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            var reqs = [];

            reqs.push(this.$store.dispatch("fetchLiveData").then( () => {
                this.liveData = this.$store.getters.getLiveData;
                if (this.liveData) {
                    this.liveData = this.liveData.filter(m => m.fmpSeasonId == this.seasonId && m.match_time > 0 && m.status != "closed");
                }
            }));

            reqs.push(this.$store.dispatch("fetchData",  {filename: "leagues/league_" + this.seasonId + ".json"}).then( (res) => {
                this.detailData = res;
                // remove placeholder matches from schedule
                this.fullSchedule = [...this.detailData.schedule.filter(m => {
                    let regex = "^Team [A-Z]{1,2}$";
                    let ht = this.detailData.teams.find(t => t.fmpTeamId == m.fmpTeamHomeId);
                    let at = this.detailData.teams.find(t => t.fmpTeamId == m.fmpTeamAwayId);
                    if (ht == null || at == null || ht.teamName.match(regex) || at.teamName.match(regex)) { return false; }
                    return true;
                })];
            }));

            Promise.all(reqs).then( () => {
                this.sortSchedule(this.fullSchedule);

                this.matchList = mostRecentMatches(this.fullSchedule);
                if (this.matchList && this.matchList.length > 0) {
                    this.$emit("mostRecentMatch", this.matchList[0]._daysAgo, this.competition);
                }
                // find live match advanced furthest, if none, take 1st element from most recent match list

                var mrm; // most recent match
                if (this.liveData.length > 0) {
                    mrm = this.liveData.reduce((a,b) => a.match_time > b.match_time ? a : b);
                    //console.log("found mrm in live data: " + mrm.fmpMatchId);
                }
                else {
                    // no live data, return nearest by time
                    mrm = this.matchList[0];
                    //console.log("taking mrm from matchlist " + mrm.fmpMatchId);
                }
                var idx = this.fullSchedule.findIndex(m => m.fmpMatchId == mrm.fmpMatchId);
                this.abbrevSchedule = this.fullSchedule.slice(Math.max(0, idx-10), Math.min(this.fullSchedule.length, idx+10));
                idx = this.abbrevSchedule.findIndex(m => m.fmpMatchId == mrm.fmpMatchId);
                this.options.start = idx;
                this.dataReady = true;
            });
        },
        sortSchedule(s) {
            let now = moment();
            s.forEach(el => {
                let _live_match = this.liveData.find(m => m.fmpMatchId == el.fmpMatchId && m.match_time > 0 && m.status == "live");
                if (_live_match) {
                    el._match_live_time = _live_match.match_time;
                }
                else if (moment(el.matchDate) > now) {
                    el._match_live_time = -10000;
                }
                else {
                    el._match_live_time = 10000;
                }
            });
            s.sort((a, b) => b._match_live_time - a._match_live_time || moment(a.matchDate) - moment(b.matchDate));
        },
        /* called from HomeView if "tab" changes, ensures that the correct start index is picked up (seems to be ignored if v-show is false) */
        refresh() {
            this.dataReady = false;
            this.$nextTick(() => {
                this.dataReady = true;
                this.$forceUpdate();
            });
        }
    },
    data() {
        return {
            dataReady: false,
            detailData: null,
            options: {
                    arrows: false,
                    pagination: false,
                    gap: '8px',
                    padding: '24px',
                    start: 0,
            },
            matchList: null,
            fullSchedule: null,
            abbrevSchedule: null,
            liveData: null,
        };
    },
}
</script>

<style lang="scss">
</style>