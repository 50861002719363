<template>
    <div class="news">

        <div class="container">
            <div class="grid">
                <div class="col-12">

                    <div class="pageHeader">
                        <h1>News</h1>
                        <div class="pageHeader__right">
                            <select v-model="competitionFilter" dir="rtl">
                                <option value="-1">Alle</option>
                                <option v-for="c in competitions" :value="c.id" dir="ltr" v-bind:key="c.id">{{ c.name }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="news__content">
                        <template v-for="article, idx in filteredArticles" >
                            <NewsCard v-if="idx < limit" v-bind:key="article.id" :article="article" />
                        </template>

                    </div>

                    <div class="news__buttons" v-if="limit < filteredArticles.length">
                        <button class="" @click="limit = limit + 12">Mehr anzeigen</button>
                    </div>

                    <div v-if="filteredArticles == null || filteredArticles.length == 0">Es wurden keine Artikel gefunden.</div>
               
                </div><!-- Close .col -->
            </div><!-- Close .grid -->
        </div> <!-- Close .container -->

    </div> <!-- Close .news -->
</template>

<script>
import NewsCard from '@/components/NewsCard.vue'
import moment from 'moment';

export default {
    name: 'NewsView',
    data() {
        return {
            articles: null,
            competitions: null,
            competitionFilter: -1,
            limit: 12,
        }
    },
    components: {
        NewsCard
    },
    mounted() {
        let filterParam = this.$route.query.filterLeague;
        this.init(filterParam);
    },
    methods: {
        init(filterParam) {
            if (filterParam) {
                this.competitionFilter = parseInt(filterParam);
            }
            this.$store.dispatch('fetchArticles').then(() => {
                this.articles = this.$store.getters.getArticles;
            });
            this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
            });
        }
    },
    computed: {
        filteredArticles() {
            var fa = [];
            if (this.articles == null) { return []; }
            if (this.competitionFilter == -1) {
                fa = this.articles;
            }
            else {
                fa = this.articles.filter(a => {
                    if (a.competitions != null) {
                        let c = a.competitions.find(c => c.id == this.competitionFilter);
                        if (c != null) { return true;}
                    }
                    return false;
                });
            }
            
            return fa.sort((b,a) => {
                return moment(a.go_live_at || a.meta.first_published_at) - moment(b.go_live_at || b.meta.first_published_at);
            });
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.query.filterLeague);
        next();
    },
}
</script>

<style lang="scss">
    .news {
        &__content {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            @media (min-width: $breakpoint-sm) {
                gap: 24px;
            }
        }

        &__buttons {
            text-align: center;
            margin-top: 10px;
        }

        .news-card {
            width: 100%;

            @media (min-width: $breakpoint-sm) {
                width: calc((100% - 24px) / 2);
            }

            @media (min-width: $breakpoint-md) {
                width: calc((100% - 48px) / 3);
            }
        }
    }
</style>

<style lang="scss" scoped>
    /* Fix for Firefox */
    @-moz-document url-prefix() {
      select, option {
        direction: ltr;
      }
    }
</style>