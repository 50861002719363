import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LeagueView from '../views/LeagueView.vue';
import LeagueRankingView from '../views/LeagueRankingView.vue';
import TeamView from '../views/TeamView.vue';
import TeamInfoView from '../views/TeamInfoView.vue';
import NewsView from '../views/NewsView.vue';
import ProfileView from '../views/ProfileView.vue';
import { bootstrap } from 'vue-gtag'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/liga/:id',
        name: 'league',
        component: LeagueView,
    },
    {
        path: '/liga/:id/tabelle',
        name: 'ranking',
        component: LeagueRankingView,
    },
    {
        path: '/liga/:id/spielplan',
        name: 'schedule',
        component: () => import(/* webpackChunkName: "schedule" */ '../views/LeagueScheduleView.vue')
    },
    {
        path: '/liga/:id/statistiken',
        name: 'statistics',
        component: () => import(/* webpackChunkName: "statistics" */ '../views/LeagueStatisticsView.vue')
    },
    {
        path: '/liga/:id/liveticker',
        name: 'liveticker',
        component: () => import(/* webpackChunkName: "liveticker" */ '../views/LeagueLiveTickerView.vue')
    },
    {
        path: '/liga/:id/livestreams',
        name: 'livestreams',
        component: () => import(/* webpackChunkName: "livestreams" */ '../views/LeagueLiveStreamsView.vue')
    },
    {
        path: '/liga/:id/ligakarte',
        name: 'ligakarte',
        component: () => import(/* webpackChunkName: "ligakarte" */ '../views/LeagueMapView.vue')
    },
    {
        path: '/team/:id/:leagueId',
        name: 'team',
        component: TeamView,
    },
    {
        path: '/team/:id/kader/:leagueId',
        name: 'teamSquad',
        component: () => import(/* webpackChunkName: "teamSquad" */ '../views/TeamSquadView.vue')
    },
    {
        path: '/team/:id/infos/:leagueId',
        name: 'teamInfos',
        component: TeamInfoView
    },
    {
        path: '/team/:id/transfers/:leagueId',
        name: 'teamTransfers',
        component: () => import(/* webpackChunkName: "teamTransfers" */ '../views/TeamTransfersView.vue')
    },
    {
        path: '/team/:id/spielplan/:leagueId',
        name: 'teamSchedule',
        component: () => import(/* webpackChunkName: "teamSchedule" */ '../views/TeamScheduleView.vue')
    },
    {
        path: '/team/:id/statistiken/:leagueId',
        name: 'teamStatistics',
        component: () => import(/* webpackChunkName: "teamStatistics" */ '../views/TeamStatisticsView.vue')
    },
    {
        path: '/team/:id/tabelle/:leagueId',
        name: 'teamRanking',
        component: () => import(/* webpackChunkName: "teamRanking" */ '../views/TeamRankingView.vue')
    },
    {
        path: '/team/:id/news/:leagueId',
        name: 'teamNews',
        component: () => import(/* webpackChunkName: "teamNews" */ '../views/TeamNewsView.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: NewsView
    },
    {
        path: '/news/:id',
        name: 'newsdetail',
        component: () => import(/* webpackChunkName: "newsdetail" */ '../views/NewsViewDetail.vue')
    },
    {
        path: '/profile/:id/:leagueId/:teamId',
        name: 'profile',
        component: ProfileView
    },
    {
        path: '/match/:id/:leagueId',
        name: 'match',
        component: () => import(/* webpackChunkName: "match" */ '../views/MatchView.vue')
    },
    {
        path: '/search',
        name: 'search',
        component: () => import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
    },
    {
        path: '/info/:id',
        name: 'basicPage',
        component: () => import(/* webpackChunkName: "basicPage" */ '../views/BasicPageView.vue')
    }
]

/* eslint-disable no-unused-vars */

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
});

var gaStatus = false;

router.beforeEach((to, from, next) => {
    /* eslint-disable no-undef */
    if (__tcfapi) {
        __tcfapi('getTCData', 2, (tcdata, success) => {
            if (tcdata.customVendorConsents && tcdata.customVendorConsents.s26) {
                if (!gaStatus) {
                    gaStatus = true;
                    bootstrap().then((gtag) => {
                        // all done!
                    })
                    console.log("google analytics opted in");
                }
            }
            else {
                if (gaStatus) {
                    gaStatus = false;
                    console.log("google analytics opted out");
                    window['ga-disable-G-4QMQ4S2TH8'] = true;
                    //window.location.reload();
                }
            }
        });
    }
    next();
});


export default router;
