<template>

    <div v-if="league && detailData && seasonInfo" class="league">

        <LeagueSubNavigation v-if="dataReady" :key="leagueId" @reinit="init" :league="league" :season="seasonInfo" :baseUrl="baseUrl" :otherSeasons="detailData.seasons"></LeagueSubNavigation>

        <!-- Teams -->
        <div v-if="league.competitionType == 1 && table" class="league__teams" v-bind:class="table.length > 14 ? 'many-teams' : ''">
            <div class="container">
                <div class="league__teams__content">
                    <div v-for="team in table" v-bind:key="team.fmpTeamId" class="league__teams__item">
                        <router-link :to="'/team/' + teamSlug(team) + '/' + league.id"><img :src="teamLogo(team)" :alt="'Logo ' + team.teamName"></router-link>
                    </div>
                </div>
            </div>
        </div>

        <DisplayAd adType="Billboard"></DisplayAd>
        <MobileAd adType="Mobile_Ad"></MobileAd>

        <div class="container">
            <div class="grid">

                <div class="col-12">
                    <div class="grid">

                        <!-- Aktuelle Spiele -->
                        <div :class="league.competitionType == 1 ? 'col-sm-6 col-md-8' : 'col-12'">
                            <div class="section">
                                <div class="section__title">
                                    <h2>Aktuelle Spiele</h2>
                                    <router-link :to="'/liga/' + leagueSlug(league) + '/spielplan'" class="section__title__more">
                                        Spielplan
                                    </router-link>
                                </div>

                                <!-- MatchCards for Leagues -->
                                <div v-if="league.competitionType == 1" class="grid hidden-xs-down">
                                    <div v-for="m, idx in matchList" v-bind:key="m.fmpMatchId" :class="idx > 2 ? 'hidden-sm-down col-sm-6' : 'col-sm-12 col-md-6'">
                                        <MatchCard :matchInfo="m" :teams="detailData.teams" :competition="league"/>
                                    </div>
                                </div>
                                
                                <!-- MatchCards for Cups (e.g. DHB-Pokal)-->
                                <div v-else class="grid hidden-xs-down">
                                    <div v-for="m, idx in matchList" v-bind:key="m.fmpMatchId" :class="idx > 3 ? 'hidden-sm-down col-sm-4' : 'col-sm-6 col-md-4'">
                                        <MatchCard :matchInfo="m" :teams="detailData.teams" :competition="league"/>
                                    </div>
                                </div>

                                <div class="grid hidden-sm-up">
                                    <div class="col-sm-12 col-md-6">
                                        <Splide :options="options" :slides="abbrevSchedule">
                                            <SplideSlide v-for="m in abbrevSchedule" v-bind:key="m.fmpMatchId" >
                                                <MatchCard :matchInfo="m" :teams="detailData.teams" :competition="league"/>
                                            </SplideSlide>
                                        </Splide>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Tabelle -->
                        <div v-bind:key="-league.id" v-if="league.competitionType == 1" class="col-sm-6 col-md-4">
                            <div class="section">
                                <div class="section__title">
                                    <h2>Tabelle</h2>
                                    <router-link :to="'/liga/' + leagueSlug(league) + '/tabelle'" class="section__title__more">
                                        Details
                                    </router-link>
                                </div>
                                <RankingPreviewLeague :key="league.fmpSeasonId" :competitionId="league.id" :seasonId="league.fmpSeasonId"/>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Hinweis für Punktabzug unterhalb von Tabelle (Desktop)-->
                <div v-if="league.fmpSeasonId == 1544"  class="col-12">
                    <div class="grid justify-end">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-bleed-y">
                            <div class="rankinghint">
                                Abzug von 4 Pluspunkten SV Werder Bremen wegen Verstoß gegen die Lizenzierungsrichtlinien.
                                <br/>Das Spiel Lintfort - Regensburg wurde aufgrund des Einsatzes einer nicht spielberechtigten Spielerin mit 0:2 Punkten und 0:0 Toren gewertet.
                            </div>
                        </div>
                    </div>
                </div>

                <!-- News -->
                <div class="col-12">
                    <div class="section">
                        <div class="section__title">
                            <h2>News</h2>
                            <router-link :to="'/news?filterLeague=' + leagueId" class="section__title__more">
                                Mehr News
                            </router-link>
                        </div>
                        <HomeNews :key="leagueId" :filterLeague="leagueId" ref="newsContainer"/>
                    </div>
                </div>

                <MobileAd adType="Mobile_MR"></MobileAd>
                <DisplayAd adType="QualityFooter"></DisplayAd>

                <!-- Statistiken -->
                <div v-if="league.competitionType == 1" class="col-12">
                    <div class="section">
                        <div class="section__title">
                            <h2>Top-Spielerinnen</h2>
                            <router-link :to="'/liga/' + leagueSlug(league) + '/statistiken'" class="section__title__more">
                                Alle Statistiken
                            </router-link>
                        </div>
                        <HomeStatistics :key="league.fmpSeasonId" :seasonId="league.fmpSeasonId" :competitionId="league.id"/>

                    </div>
                </div>

            </div> <!-- Close .grid -->
        </div> <!-- Close .container -->

  </div>

</template>

<script>
import LeagueSubNavigation from '@/components/LeagueSubNavigation.vue';
import RankingPreviewLeague from '@/components/RankingPreviewLeague.vue';
import HomeNews from '@/components/HomeNews.vue';
import HomeStatistics from '@/components/HomeStatistics.vue';
import MatchCard from '@/components/MatchCard.vue';
import {mostRecentMatches, teamLogoUrl, slugify} from '@/hbf-helper';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import moment from 'moment';
import MobileAd from "@/components/MobileAd.vue";
import DisplayAd from "@/components/DisplayAd.vue";

export default {
    name: 'LeagueView',
    components: {
        LeagueSubNavigation, RankingPreviewLeague, MatchCard, HomeNews, HomeStatistics, MobileAd, DisplayAd, Splide, SplideSlide
    },
    data() {
        return {
            leagueId: null,
            liveData: null,
            competitions: null,
            league: null,
            detailData: null,
            seasonInfo: null,
            table: null,
            matchList: null,
            abbrevSchedule: null,
            fullSchedule: null,
            dataReady: false,
            options: {
                    arrows: false,
                    pagination: false,
                    gap: '8px',
                    padding: '24px',
                    start: 0,
            },
        };
    },
    /* TODO: Nur ein Workaround - muss umgebaut werden á la SportlerPlus mit meta {} im router.js */
    mounted () {
        this.init(this.$route.params.id);
    },
    unmounted () {
        document.body.classList.remove('league')
    },
    methods: {
        async init(leagueId) {
            await this.$store.dispatch("fetchLiveData").then( () => {
                this.liveData = this.$store.getters.getLiveData;
            });

            this.dataReady = false;
            document.body.classList.add('league');
            this.leagueId = parseInt(leagueId);

            this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
                this.league = this.competitions.find(el => el.id == this.leagueId);

                if (this.league) {

                    if (this.liveData) {
                        this.liveData = this.liveData.filter(m => m.fmpSeasonId == this.league.fmpSeasonId && m.match_time > 0 && m.status != "closed");
                    }
                    
                    this.$store.dispatch("fetchData",  {filename: "leagues/league_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        this.detailData = res;
                        var season_key = this.league.fmpSeasonId;
                        this.seasonInfo = this.detailData.seasons.find(s => s.fmpSeasonId == season_key);
                        this.$store.dispatch("fetchData",  {filename: "tables/table_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                            this.table = res.table;
                        });

                        this.fullSchedule = [...this.detailData.schedule.filter(m => {
                            let regex = "^Team [A-Z]{1,2}$";
                            let ht = this.detailData.teams.find(t => t.fmpTeamId == m.fmpTeamHomeId);
                            let at = this.detailData.teams.find(t => t.fmpTeamId == m.fmpTeamAwayId);
                            if (ht == null || at == null || ht.teamName.match(regex) || at.teamName.match(regex)) { return false; }
                            return true;
                        })];
                        //this.fullSchedule.sort((a,b) => moment(a.matchDate) - moment(b.matchDate));
                        this.sortSchedule(this.fullSchedule);

                        this.matchList = mostRecentMatches(this.fullSchedule);

                        var mrm; // most recent match
                        if (this.liveData.length > 0) {
                            mrm = this.liveData.reduce((a,b) => a.match_time > b.match_time ? a : b);
                        }
                        else {
                            // no live data, return nearest by time
                            mrm = this.matchList[0];
                        }

                        var idx = this.fullSchedule.findIndex(m => m.fmpMatchId == mrm.fmpMatchId);
                        this.abbrevSchedule = this.fullSchedule.slice(Math.max(0, idx-10), Math.min(this.fullSchedule.length, idx+10));
                        idx = this.abbrevSchedule.findIndex(m => m.fmpMatchId == mrm.fmpMatchId);
                        this.options.start = idx;

                        this.$nextTick(() => { this.dataReady = true });
                    });
                }
            });
        },
        async sortSchedule(s) {
            let now = moment();
            s.forEach(el => {
                let _live_match = this.liveData.find(m => m.fmpMatchId == el.fmpMatchId && m.match_time > 0 && m.status == "live");
                if (_live_match) {
                    el._match_live_time = _live_match.match_time;
                }
                else if (moment(el.matchDate) > now) {
                    el._match_live_time = -10000;
                }
                else {
                    el._match_live_time = 10000;
                }
            });
            s.sort((a, b) => b._match_live_time - a._match_live_time || moment(a.matchDate) - moment(b.matchDate));
        },
        teamLogo(team) {
            return teamLogoUrl(team, 100, this.baseUrl, this.league.fmpSeasonId);
        },
        leagueSlug(league) {
            if (parseInt(league.name) == league.id) {
                return slugify(league.name);
            }
            return league.id + "--" + slugify(league.name);
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        }
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.params.id);
        next();
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss">

    .league {

        &__header {
            position: relative;
            display: flex;
            align-items: flex-end;
            text-align: center;
            height: 300px;
            margin-top: -104px;

            @media (min-width: $breakpoint-sm) {
                height: 520px;
                text-align: left;
            }

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(135deg, #691A55 0%, #2B0722 100%);
                opacity: .56;
            }

            &:after {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 67%);
                opacity: .32;
            }

            img {
                position: absolute;
                z-index: -2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            h1 {
                color: $colorWhite;
                margin: 0 0 4px 0;
                font-size: 32px;

                @media (min-width: $breakpoint-sm) {
                    font-size: 48px;
                    margin: 0 0 9px 0;
                }
            }

            span {
                display: flex;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: rgba($colorWhite ,.8);
                margin-bottom: 6px;

                @media (min-width: $breakpoint-sm) {
                    font-size: 17px;
                    justify-content: flex-start;
                    margin-bottom: 28px;
                }
            }

            select {
                all: unset;
                position: relative;
                display: inline-flex;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
                color: $colorWhite;
                opacity: .8;
                margin-bottom: 6px;
                padding-right: 16px;
                background: url('@/assets/ic_12_dropdown.svg');
                background-position: center right;
                background-repeat: no-repeat;

                @media (min-width: $breakpoint-sm) {
                    font-size: 17px;
                    justify-content: flex-start;
                    margin-bottom: 28px;
                }

                option {
                    background: $colorBlack;
                }
            }
        }

        &__teams {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                width: 32px;
                height: 100%;
                background-image: linear-gradient(270deg, $colorWhite 0%, rgba($colorWhite, 0) 100%);
            }

            &__content {
                position: relative;
                display: flex;
                width: calc(100% + 48px);
                margin: 24px -24px 12px;
                padding: 0 24px;
                overflow-x: auto;
                overflow-y: hidden;

                &:after {
                    content: "";
                    padding-right: 12px;

                    @media (min-width: $breakpoint-xs) {
                        display: none;
                    }
                }

                @media (min-width: $breakpoint-xs) {
                    width: 100%;
                    margin: 48px 0 12px;
                    padding: 0;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    overflow: visible;
                }
            }

            &__item {
                display: inline-block;
                flex-shrink: 0;
                position: relative;
                padding: 12px;
                border-radius: 12px;
                margin-right: 8px;
                background: $colorWhite;
                box-shadow: 0 2px 8px 0 rgba($colorBlack,0.02), inset 0 0 0 1px rgba($colorBlack, .06);
                transition: all .3s ease-in-out;

                @media (min-width: $breakpoint-xs) {
                    margin-bottom: 8px;
                }

                @media (min-width: $breakpoint-sm) {
                    margin-right: 0;
                    margin-bottom: 16px;

                    &:not(:nth-child(7)):not(:nth-child(14)) {
                        margin-right: 32px;
                    }

                    .many-teams & {

                        &:not(:nth-child(7)):not(:nth-child(14)) {
                            margin-right: 0;
                        }

                        &:not(:nth-child(8)):not(:nth-child(16)) {
                            margin-right: 24px;
                        }
                    }

                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: 0 6px 8px 0 rgba($colorBlack,0.04), inset 0 0 0 1px rgba($colorBlack, .06);
                    }
                }

                @media (min-width: $breakpoint-md) {
                    padding: 8px;
                    margin-right: 0 !important;
                    margin-bottom: 8px;
                }

                @media (min-width: $breakpoint-lg) {
                    padding: 14px;
                    margin-bottom: 0;
                }

                img {
                    display: flex;
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                    object-position: center center;
                }
            }
        }
    }
    
</style>