<template>
    <div class="profile">
        <div class="container">
            <div class="grid">
                <div class="col-12 hidden-xs-down">
                        
                    <div v-if="teamSquad && selectedPlayer" class="profile__hero">
                        <Splide :key="1" :options="options" :slides="teamSquad" :transition="customTransition" @splide:moved="loadProfile">
                            <SplideSlide v-for="s in teamSquad" v-bind:key="s.fmpContractId" >
                                <div class="profile__hero__item">
                                    <div class="profile__hero__item__image">
                                        <img :src="'https://hbf-helper.deinsportplatz.de/images/contract/' + s.fmpContractId + '/' + s.player.fmpPlayerId + '/landscape/' + league.fmpSeasonId" alt="">
                                    </div>
                                    <div class="profile__main">
                                        <div class="profile__main__name">
                                            <span>{{ s.player.firstName }}</span>
                                            {{ s.player.lastName }}
                                        </div>
                                        <div class="profile__main__pills">
                                            <router-link :to="'/team/' + teamSlug(team) + '/' + league.id" class="profile__main__pills__item">
                                                <img :src="teamLogo(team)" alt="">
                                                {{ team.teamName }}
                                            </router-link>
                                            <router-link :to="'/liga/' + leagueSlug(league)" class="profile__main__pills__item">
                                                <img src="@/assets/logo.svg" alt="">
                                                {{ league.name }}
                                            </router-link>
                                        </div>
                                        <div class="profile__main__facts">
                                            <div class="profile__main__facts__item">
                                                <div class="profile__main__facts__item__value">
                                                    {{ playerAge(s) }}
                                                </div>
                                                <div class="profile__main__facts__item__label">
                                                    Alter
                                                </div>
                                            </div>
                                            <div class="profile__main__facts__item">
                                                <div class="profile__main__facts__item__value">
                                                    {{ s.number }}
                                                </div>
                                                <div class="profile__main__facts__item__label">
                                                    Nummer
                                                </div>
                                            </div>
                                            <div class="profile__main__facts__item">
                                                <div class="profile__main__facts__item__value">
                                                    {{ translatePosition(s.position) }}
                                                </div>
                                                <div class="profile__main__facts__item__label">
                                                    Position
                                                </div>
                                            </div>
                                            <div v-if="selectedPlayer.player.nationality" class="profile__main__facts__item">
                                                <div class="profile__main__facts__item__value">
                                                    <img :src="playerFlag(s)" alt="">
                                                </div>
                                                <div class="profile__main__facts__item__label">
                                                    Nationalität
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </SplideSlide>
                        </Splide>

                    </div>
                    <div v-else>
                        Fehler beim Laden der Profil-Daten.
                    </div>

                </div>

                <div class="col-12 hidden-sm-up">

                    <div v-if="teamSquad" class="profile__hero">
                        <Splide :key="2" :options="options" :slides="teamSquad" :transition="customTransition"  @splide:moved="loadProfile">
                            <SplideSlide v-for="s in teamSquad" v-bind:key="-s.fmpContractId">
                                <div class="profile__hero__item">
                                    <div class="profile__hero__item__image">
                                        <router-link :to="'/team/' + teamSlug(team) + '/' + league.id" class="profile__hero__item__image__logo">
                                            <img :src="teamLogo(team)"  alt="">
                                        </router-link>
                                        <img :src="'https://hbf-helper.deinsportplatz.de/images/contract/' + s.fmpContractId + '/' + s.player.fmpPlayerId + '/landscape/' + league.fmpSeasonId" alt="">
                                    </div>
                                </div>
                            </SplideSlide>
                        </Splide>
                    </div>

                    <div v-if="selectedPlayer && selectedPlayer.player" key="selectedPlayer.fmpContractId" class="profile__main">
                        <div class="profile__main__name">
                            <span>{{ selectedPlayer.player.firstName }}</span>
                            {{ selectedPlayer.player.lastName }}
                        </div>
                        <div class="profile__main__facts">
                            <div class="profile__main__facts__item">
                                <div class="profile__main__facts__item__value">
                                    {{ playerAge(selectedPlayer) }}
                                </div>
                                <div class="profile__main__facts__item__label">
                                    Alter
                                </div>
                            </div>
                            <div class="profile__main__facts__item">
                                <div class="profile__main__facts__item__value">
                                    {{ selectedPlayer.number }}
                                </div>
                                <div class="profile__main__facts__item__label">
                                    Nummer
                                </div>
                            </div>
                            <div class="profile__main__facts__item">
                                <div class="profile__main__facts__item__value">
                                    {{ translatePosition(selectedPlayer.position) }}
                                </div>
                                <div class="profile__main__facts__item__label">
                                    Position
                                </div>
                            </div>
                            <div v-if="selectedPlayer.player.nationality" class="profile__main__facts__item">
                                <div class="profile__main__facts__item__value">
                                    <img :src="playerFlag(selectedPlayer)" alt="">
                                </div>
                                <div class="profile__main__facts__item__label">
                                    Nationalität
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="selectedPlayer" class="col-12">
                    <div class="grid">

                        <div class="col-sm-4">

                            <div class="section">

                                <div class="section__title">
                                    <h2>Persönliche Daten</h2>
                                </div>

                                <div class="list">

                                    <div v-if="selectedPlayer.player.birthDate" class="profile__pair">
                                        <div class="profile__pair__label">
                                            Geburtsdatum
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ formatDate(selectedPlayer.player.birthDate) }}
                                        </div>
                                    </div>

                                    <div v-if="selectedPlayer.player.height" class="profile__pair">
                                        <div class="profile__pair__label">
                                            Größe
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ (selectedPlayer.player.height/100).toFixed(2) }} m
                                        </div>
                                    </div>

                                    <div v-if="selectedPlayer.player.nationality" class="profile__pair">
                                        <div class="profile__pair__label">
                                            Nationalität
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ nationalityAsString(selectedPlayer.player.nationality) }}
                                        </div>
                                    </div>

                                </div>
                                
                            </div>

                        </div>

                        <div class="col-sm-4">
                            <div class="section">

                                <div class="section__title">
                                    <h2>Vertrag</h2>
                                </div>

                                <div class="list">

                                    <div class="profile__pair">
                                        <div class="profile__pair__label">
                                            Verein
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ team.teamName }}
                                        </div>
                                    </div>

                                    <!-- <div class="profile__pair">
                                        <div class="profile__pair__label">
                                            Vertrag seit
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ formatDate(selectedPlayer.start) }}
                                        </div>
                                    </div> -->

                                    <div class="profile__pair">
                                        <div class="profile__pair__label">
                                            Vertrag bis
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ formatDate(selectedPlayer.end) }}
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div class="col-sm-4">

                            <div class="section">

                                <div class="section__title">
                                    <h2>Leistungsdaten</h2>
                                </div>

                                <div class="list" v-if="selectedPlayerStatistics">

                                    <div v-if="selectedPlayerStatistics.position == 'goalkeeper'" class="profile__pair">
                                        <div class="profile__pair__label">
                                            Paraden
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ selectedPlayerStatistics.saves }} <small v-if="selectedPlayerStatistics.saves > 0 && selectedPlayerStatistics.totalMatches > 0">({{ (selectedPlayerStatistics.saves / selectedPlayerStatistics.totalMatches).toFixed(1) }} pro Spiel)</small>
                                        </div>
                                    </div>

                                    <div v-else class="profile__pair">
                                        <div class="profile__pair__label">
                                            Tore
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ selectedPlayerStatistics.goals }} <small v-if="selectedPlayerStatistics.goals > 0 && selectedPlayerStatistics.totalMatches > 0">({{ (selectedPlayerStatistics.goals / selectedPlayerStatistics.totalMatches).toFixed(1) }} pro Spiel)</small>
                                        </div>
                                    </div>

                                    <div class="profile__pair">
                                        <div class="profile__pair__label">
                                            Assists
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ selectedPlayerStatistics.assists }} <small v-if="selectedPlayerStatistics.assists > 0 && selectedPlayerStatistics.totalMatches > 0">({{ (selectedPlayerStatistics.assists / selectedPlayerStatistics.totalMatches).toFixed(1) }} pro Spiel)</small>
                                        </div>
                                    </div>

                                    <div class="profile__pair">
                                        <div class="profile__pair__label">
                                            Spiele
                                        </div>
                                        <div class="profile__pair__value">
                                            {{ selectedPlayerStatistics.totalMatches }}
                                        </div>
                                    </div>

                                </div>

                                <div v-else class="list list--empty">
                                    <div class="list__empty">
                                        <div class="list__empty__title">
                                            Keine Statistiken vorhanden
                                        </div>
                                        <div class="list__empty__subtitle">
                                            Es wurden bisher keine Statistiken zu dieser Spielerin erfasst
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        
                    </div>
                </div>

                <!-- <div class="col-12">
                    <div class="section">
                        <div class="section__title">
                            <h2>News</h2>
                            <router-link to="/news" class="section__title__more">
                                Mehr News
                            </router-link>
                        </div>
                        <HomeNews/>
                    </div>
                </div> -->

                <!-- <div class="col-12">
                    <div class="section">
                        <div class="section__title hidden-sm-up">
                            <h2>Fotos</h2>
                        </div>
                        <ImageGallery/>
                    </div>
                </div> -->

            </div>
        </div>
    </div> <!-- Close .profile -->
</template>

<script>
//import HomeNews from '@/components/HomeNews.vue';
//import ImageGallery from '@/components/ImageGallery.vue';
import { MyTransition } from '@/TransformScaleTransition';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { POSITIONS, teamLogoUrl, slugify, countryList } from "@/hbf-helper";
import moment from 'moment';

export default {
    name: 'ProfileView',
    components: {
        /* HomeNews,*/ Splide, SplideSlide
    },
    mounted () {
        this.init(this.$route.params.id, this.$route.params.leagueId, this.$route.params.teamId);
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.params.id, to.params.leagueId, to.params.teamId);
        next();
    },
    methods: {
        /* eslint-disable no-unused-vars */

        loadProfile(s, newIndex, prevIndex, destIndex) {
            this.selectedPlayer = this.teamSquad[newIndex];
            if (this.playerStatistics) {
                this.selectedPlayerStatistics = this.playerStatistics.find(ps => ps.fmpPlayerId == this.selectedPlayer.player.fmpPlayerId);
            }
        },
        init(playerId, leagueId, teamId) {
            this.teamId = parseInt(teamId);
            this.leagueId = parseInt(leagueId);
            this.playerId = parseInt(playerId);

            this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
                this.league = this.competitions.find(el => el.id == this.leagueId);

                if (this.league) {
                    this.$store.dispatch("fetchData",  {filename: "players/players_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        if (res.playerStatistics) {
                            this.playerStatistics = res.playerStatistics;
                            this.selectedPlayerStatistics = this.playerStatistics.find(ps => ps.fmpPlayerId == this.playerId);
                        }
                        else {
                            this.playerStatistics = null;
                            this.selectedPlayerStatistics = null;
                        }
                    });

                    this.$store.dispatch("fetchData",  {filename: "leagues/league_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        this.detailData = res;
                        var season_key = this.league.fmpSeasonId;
                        this.seasonInfo = this.detailData.seasons.find(s => s.fmpSeasonId == season_key);
                        
                        this.$store.dispatch("fetchData",  {filename: "teams/team_" + this.teamId + "_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                            this.team = res;
                            let years = this.seasonInfo.year.split("/");

                            let seasonStart = moment( years[0] + "-07-01");
                            let seasonEnd = moment( years[1] + "-06-30");
                            let transferWindowEnd = moment( years[1] + "-05-30");

                            this.teamSquad = [];

                            /* simple hack to display basic data */
                            if (!this.team) {
                                this.selectedPlayer = {
                                    "player": this.selectedPlayerStatistics,
                                    "number": this.selectedPlayerStatistics.shirtNumber,
                                    "position": this.selectedPlayerStatistics.position,

                                }
                                if (this.selectedPlayerStatistics.contracts && this.selectedPlayerStatistics.contracts.current) {
                                    this.selectedPlayer["start"] = this.selectedPlayerStatistics.contracts.current[0].start;
                                    this.selectedPlayer["end"] = this.selectedPlayerStatistics.contracts.current[0].end;
                                }
                                this.team = this.detailData.teams.find(t => t.fmpTeamId == this.teamId);
                                this.teamSquad = [this.selectedPlayer];
                                return;
                            }

                            if (this.team.contracts.past) {
                                this.teamSquad = this.team.contracts.past;
                            }
                            if (this.team.contracts.current) {
                                this.teamSquad = this.teamSquad.concat(this.team.contracts.current);
                            }

                            let now = moment();
                            this.teamSquad = this.teamSquad.filter(s => moment(s.start) < seasonEnd && moment(s.end) > seasonStart && moment(s.end) >= transferWindowEnd);

                            if (this.teamSquad == null) { return; }
                            this.teamSquad.sort( (s1,s2) => s1.number - s2.number);
                            let playerIndex = this.teamSquad.findIndex(p => p.player.fmpPlayerId == this.playerId);

                            this.selectedPlayer = this.teamSquad[playerIndex];
                            this.options.start = playerIndex;
                        });
                    });
                }
            });
        },
        teamLogo(team) {
            return teamLogoUrl(team, 40, this.baseUrl, this.league.fmpSeasonId);
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        },
        leagueSlug(league) {
            if (parseInt(league.name) == league.id) {
                return slugify(league.name);
            }
            return league.id + "--" + slugify(league.name);
        },
        playerAge(s) {
            if (s.player.birthDate) {
                let birthDate = moment(s.player.birthDate);
                let now = moment();
                return now.diff(birthDate, "years");
            }
            return null;
        },
        translatePosition(pos) {
            var p = POSITIONS.find(p => p.key == pos);
            if (p) {
                return p.abbreviation;
            }
            else {
                return pos;
            }
        },
        nationalityAsString(n) {
            if (n == null || n.length == 0) { return ""; }
            return countryList.find(c => c.alpha2 == n).name;
        },
        contractInfo(s) {
            if (s.contract && /* s.contract.start && */ s.contract.end) {
                //let startDate = moment(s.contract.start);
                let endDate = moment(s.contract.end);
                //return startDate.year() + "-" + endDate.year();
                return endDate.format("DD.MM.YYYY");
            }
            return "";
        },
        formatDate(d) {
            let newDate = moment(d)
            return newDate.format("DD.MM.YYYY");
        },
        playerFlag(s) {
            if (s.player && s.player.nationality) {
                try {
                    return require("@/assets/flags/flag_" + s.player.nationality + ".svg");
                }
                catch(error) {
                    console.error(error);
                }
            }
            return require("@/assets/1x1.png");
        }
    },
    data() {
        return {
            teamId: null,
            leagueId: null,
            playerId: null,
            competitions: null,
            league: null,
            team: null,
            teamSquad: null,
            selectedPlayer: null,
            seasonInfo: null,
            detailData: null,
            customTransition: MyTransition,
            options: {
                type: 'slide',
                arrows: true,
                pagination: false,
                updateOnMove: true,
                start: 0,
                breakpoints: {
                    768: {
                        type: 'slide',
                        gap: '8px',
                        padding: '80px',
                    },
                }
            },
            playerStatistics: null,
            selectedPlayerStatistics: null,
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss">


    .profile {

        &__hero {
            width: 100%;

            &__item {
                display: flex;
                background: $colorWhite;
                box-shadow: inset 0 0 0 1px rgba($colorBlack, .06), 0 2px 8px 0 rgba($colorBlack ,0.02);
                border-radius: 8px;
                overflow: hidden;

                &__image {
                    position: relative;
                    width: 100%;
                    height: 180px;

                    @media (min-width: $breakpoint-sm) {
                        width: 50%;
                        height: 320px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    &__logo {
                        display: flex;
                        position: absolute;
                        width: 28px;
                        height: 28px;
                        top: 8px;
                        right: 8px;
                        background: $colorWhite;
                        box-shadow:inset 0 0 0 1px rgba($colorBlack,0.12);
                        border-radius: 4px;
                        padding: 6px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            /* Overwrites for slider width */
            .splide {
                @media (min-width: $breakpoint-sm) {
                    margin: 0;
                }
            }

            /* Overwrites for slider arrow */
            .splide__arrow {                
                &--next {
                    right: 24px !important;

                    @media (min-width: $breakpoint-sm) {
                        right: -20px !important;
                    }
                }

                &--prev {
                    left: 24px !important;
                    
                    @media (min-width: $breakpoint-sm) {
                        left: -20px !important;
                    }
                }
            }
        }

        &__main {

            @media (min-width: $breakpoint-sm) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 0 16px 0 40px;
                width: 50%;
            }


            @media (min-width: $breakpoint-md) {
                padding: 0 24px 0 64px;
            }
            
            &__name {
                display: flex;
                flex-direction: column;
                text-align: center;
                width: 100%;
                font-size: 28px;
                font-weight: 800;
                letter-spacing: -0.67px;
                color: $colorBlack;
                padding: 18px 0 11px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media screen and (min-width: $breakpoint-sm) {
                    font-size: 48px;
                    line-height: 56px;
                    letter-spacing: -1.14px;
                    text-align: left;
                }

                span {
                    font-size: 14px;
                    letter-spacing: -0.33px;
                    color: rgba($colorBlack, .48);
                    margin-bottom: -6px;

                    @media screen and (min-width: $breakpoint-sm) {
                        font-size: 24px;
                        letter-spacing: -0.57px;
                        margin-bottom: -14px;
                    }
                }
            }

            &__pills {
                display: none;
                flex-wrap: wrap;
                margin-bottom: 18px;

                @media screen and (min-width: $breakpoint-sm) {
                    display: flex;
                }

                &__item {
                    display: inline-flex;
                    background: rgba($colorBlack, .04);
                    border-radius: 4px;
                    font-size: 10px;
                    font-weight: 700;
                    color: $colorBlack;
                    padding: 6px 10px 6px 6px;
                    margin-bottom: 8px;
                    text-decoration: none;
                    transition: all .2s ease-in-out;

                    &:hover {
                        background: rgba($colorBlack, .08);
                    }

                    &:not(:last-child) {
                        margin-right: 8px;
                    }

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 8px;
                    }
                }
            }

            &__facts {
                width: calc(100% + 48px);
                margin: 0 -24px;
                display: flex;
                justify-content: center;
                padding: 6px 12px 8px;
                box-shadow: inset 0 1px 0 0 rgba($colorBlack, .08), inset 0 -1px 0 0 rgba($colorBlack, .08);

                @media (min-width: $breakpoint-sm) {
                    width: 100%;
                    margin: 0;
                    justify-content: flex-start;
                    margin-left: -28px;
                    box-shadow: none;
                    margin-bottom: 8px;
                }

                &__item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    min-width: 48px;
                    margin: 0 8px;

                    &__value {
                        font-size: 17px;
                        line-height: 24px;
                        font-weight: 700;
                        color: $colorBlack;

                        @media screen and (min-width: $breakpoint-sm) {
                            font-size: 22px;
                            line-height: 28px;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            transform: translateY(2px);

                            @media screen and (min-width: $breakpoint-sm) {
                                transform: translateY(-6px);
                            }
                        }
                    }

                    &__label {
                        font-size: 10px;
                        line-height: 16px;
                        font-weight: 600;
                        color: rgba($colorBlack, .48);
                    }
                }
            }
        }

        &__pair {
            display: flex;
            align-items: flex-end;
            padding: 11px 0;

            @media (min-width: $breakpoint-sm) {
                padding: 20px 0 21px;
            }

            &:first-child {
                padding-top: 15px;

                @media (min-width: $breakpoint-sm) {
                    padding-top: 28px;
                }
            }

            &:last-child {
                padding-bottom: 15px;

                @media (min-width: $breakpoint-sm) {
                    padding-bottom: 29px;
                }
            }

            &:not(:last-child) {
                box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
            }

            &__label {
                font-size: 14px;
                font-weight: 700;
                color: rgba($colorBlack, .48);
                width: 128px;

                @media (min-width: $breakpoint-sm) {
                    width: 108px;
                    line-height: 14px;
                }
            }

            &__value {
                font-size: 14px;
                font-weight: 700;
                color: $colorBlack;

                @media (min-width: $breakpoint-sm) {
                    font-size: 17px;
                    line-height: 15px;
                }

                a {
                    text-decoration: none;
                    color: $colorBlack;
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    .list {
        padding-top: 0;
        padding-bottom: 0;
    }

    .list__header {
        &:not(:last-child) {
            position: relative;
            box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);

            &:before {
                content: '';
                position: absolute;
                width: 56px;
                height: 1px;
                bottom: 0;
                left: 0;
                background: $colorWhite;
            }

            &:after {
                content: url('@/assets/list-arrow-teams.svg');
                position: absolute;
                bottom: -9px;
                left: 21px;
                width: 6px;
                height: 18px;
            }
        }
    }

    .imgGallery {
        @media (min-width: $breakpoint-sm) {
            margin-top: 40px;
        }
    }

    .profile__hero {

        .is-active {
            z-index: 1;
        }

        @media screen and (max-width: 767px) {

            &__item {
                .is-active & {
                    transform: scale(1) translateX(0);
                    transition: .4s all ease-in-out;
                    opacity: 1;
                }

                .is-prev &, .is-next & {
                    transition: .4s all ease-in-out;
                    opacity: .48;
                }

                .is-prev & {
                    transform-origin: right center;
                    transform: scale(0.7) translateX(25%);
                }

                .is-next & {
                    transform-origin: left center;
                    transform: scale(0.7) translateX(-25%);
                }
            }
        }
    }
</style>
