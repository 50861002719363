import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuex from 'vuex';
import VueGtag from "vue-gtag";
import { createHead } from "@vueuse/head"

const app = createApp(App);
const head = createHead();

app.use(Vuex).use(store).use(router).use(head);

app.use(VueGtag, {
    config: {
        id: "G-4QMQ4S2TH8"
    },
    bootstrap: false,
}, router);

app.mount('#app');
