<template>

    <div v-if="team && league">

        <TeamSubNavigation :team="team" :league="league" :baseUrl="baseUrl" :removeMargin="true"></TeamSubNavigation>

        <div class="container">

            <div v-if="teamData" class="grid">
                <!-- Halle -->
                <div v-if="teamData.venues" class="section col-sm-6">
                    <div class="section__title">
                        <h2>Halle</h2>

                        <!-- Falls es mehrere Hallen gibt -->
                        <ul class="section__title__tabs">
                            <li v-for="v,idx in teamData.venues" v-bind:key="v._id" :class="activeVenue == idx ? 'is-active' : ''" @click="activeVenue = idx"><!-- v.name -->Halle {{ idx+1 }}</li>
                        </ul>
                    </div>
                    <div class="list">
                        <template  v-for="v,idx in teamData.venues" v-bind:key="v._id">
                            <VenueInformation v-if="idx == activeVenue" :venue="v"></VenueInformation>
                        </template>
                    </div>
                </div>

                <!-- Kontakt -->
                <div class="section col-sm-6">
                    <div class="section__title">
                        <h2>Kontakt</h2>
                    </div>
                    <div class="list">
                        <div v-if="teamData.contact" class="stadium">
                            <!--<div class="stadium__photo">
                                <img src="@/assets/teams/mannschaft-leverkusen.jpg" alt="">
                            </div>-->
                            <div class="stadium__address">
                                <h4>{{ teamData.teamName }}</h4>
                                <p>{{ teamData.contact.street }}<br>
                                {{ teamData.contact.postal_code }} {{ teamData.contact.city }}</p>
                                <div class="hidden-sm-down">
                                    <div class="stadium__meta">
                                        <a class="stadium__meta__pill stadium__meta__pill--highlight" :href="universalMapsLink(teamData.contact)" target="_blank">
                                            <img src="@/assets/ic_10_route.svg" alt="Pfeil">
                                            Route
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="hidden-md-up">
                                <div class="stadium__meta">
                                    <a class="stadium__meta__pill stadium__meta__pill--highlight" :href="universalMapsLink(teamData.contact)" target="_blank">
                                        <img src="@/assets/ic_10_route.svg" alt="Pfeil">
                                        Route
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_handy.svg" alt="Telefon">
                            </div>
                            <div class="list__item__label">
                                +49 (0) 5621-966528
                            </div>
                        </div> -->
                        <!-- <div class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_email.svg" alt="Email">
                            </div>
                            <div class="list__item__label">
                                handball@tsvbayer04.de
                            </div>
                        </div> -->
                        <div v-if="teamData.url && teamData.url.website" class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_website.svg" alt="Website">
                            </div>
                            <div class="list__item__label">
                                <a class="plain_link" :href="teamData.url.website" target="_blank">Website</a>
                            </div>
                        </div>
                        <div v-if="teamData.url && teamData.url.ticketshop" class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_ticket.svg" alt="Ticketshop">
                            </div>
                            <div class="list__item__label">
                                <a class="plain_link" :href="teamData.url.ticketshop" target="_blank">Ticketshop</a>
                            </div>
                        </div>
                        <div v-if="teamData.url && teamData.url.facebook" class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_facebook.svg" alt="Facebook">
                            </div>
                            <div class="list__item__label">
                                <a class="plain_link" :href="teamData.url.facebook" target="_blank">Facebook</a>
                            </div>
                        </div>
                        <div v-if="teamData.url && teamData.instagramTeam" class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_instagram.svg" alt="Instagram">
                            </div>
                            <div class="list__item__label">
                                <a class="plain_link" :href="teamData.instagramTeam" target="_blank">Instagram</a>
                            </div>
                        </div>
                        <div v-if="teamData.url && teamData.youtube" class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_youtube.svg" alt="Youtube">
                            </div>
                            <div class="list__item__label">
                                <a class="plain_link" :href="teamData.youtube" target="_blank">Youtube</a>
                            </div>
                        </div>
                        <div v-if="teamData.url && teamData.url.twitter" class="list__item list__item--drilldown">
                            <div class="list__item__icon list__item__icon--24">
                                <img src="@/assets/ic_24_twitter.svg" alt="Twitter">
                            </div>
                            <div class="list__item__label">
                                <a class="plain_link" :href="teamData.url.twitter" target="_blank">Twitter</a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Historie -->
                <div v-if="teamData.historie" class="section col-sm-8">
                    <div class="section__title">
                        <h2>Historie</h2>
                    </div>
                    <div class="list">
                        <div class="history" v-html="teamHistory">
                        </div>
                    </div>
                </div>

                <div class="section col-sm-4">
                 <!-- Erfolge -->
                    <div v-if="cmsTeam && cmsTeam.successes" class="section__title">
                        <h2>Erfolge</h2>
                    </div>
                    <div v-if="cmsTeam && cmsTeam.successes" class="list">
                        <div v-for="s,idx in cmsTeam.successes" v-bind:key="idx" class="list__header">
                            <div class="list__header__icon list__header__icon--avatar">
                                <img :src="success_icon(s.success_type)" alt="">
                            </div>
                            <div class="list__header__text">
                                <div class="list__header__text__label" style="white-space: normal;">{{ s.success_year }}</div>
                                <div class="list__header__text__value">
                                    {{ success_type_as_text(s.success_type) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Trikots -->
                    <div class="section__title">
                        <h2>Trikots</h2>
                    </div>
                    <div class="list">
                        <TeamJersey v-if="teamData.kits.Home && teamData.kits.Home.Player" :jerseyColor="teamData.kits.Home.Player.shirtColor1" :jerseyColorName="''" :jerseyType="teamData.kits.Home.Player.shirtName"></TeamJersey>
                        <TeamJersey v-if="teamData.kits.Guest && teamData.kits.Guest.Player" :jerseyColor="teamData.kits.Guest.Player.shirtColor1" :jerseyColorName="''" :jerseyType="teamData.kits.Guest.Player.shirtName"></TeamJersey>
                        <TeamJersey v-if="teamData.kits.Other && teamData.kits.Other.Player && teamData.kits.Other.Player.numberColor" :jerseyColor="teamData.kits.Other.Player.shirtColor1" :jerseyColorName="''" :jerseyType="teamData.kits.Other.Player.shirtName"></TeamJersey>                    
                    </div>
                </div>

            </div> <!-- Close .grid -->
        </div> <!-- Close .container -->

    </div>

</template>

<script>
import TeamSubNavigation from '@/components/TeamSubNavigation.vue';
import VenueInformation from '@/components/VenueInformation.vue';
import TeamJersey from '@/components/TeamJersey.vue';
import { SUCCESS_TYPES } from '@/hbf-helper';
import moment from 'moment';

export default {
    name: 'TeamInfoView',
    components: { TeamSubNavigation, VenueInformation, TeamJersey },
    data() {
        return {
            teamId: null,
            leagueId: null,
            competitions: null,
            league: null,
            detailData: null,
            team: null,
            seasonInfo: null,
            teamData: null,
            activeVenue: 0,
            cmsTeamList: null,
            cmsTeam: null,
        };
    },
    methods: {
        success_type_as_text(s) {
            return SUCCESS_TYPES.find(sc => sc.id == s).text;
        },
        success_icon(s) {
            let icon_name = SUCCESS_TYPES.find(sc => sc.id == s).icon;
            try {
                return require("@/assets/" + icon_name);
            }
            catch(error) {
                console.error(error);
            }
            return require("@/assets/1x1.png");
        },
        init(teamId, leagueId) {
            this.teamId = parseInt(teamId);
            this.leagueId = parseInt(leagueId);


            this.$store.dispatch("fetchTeams").then( () => {
                this.cmsTeamList = this.$store.getters.getTeams;
                this.cmsTeam = this.cmsTeamList.find(t => t.fmpForeignId == this.teamId);
            });

            this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
                this.league = this.competitions.find(el => el.id == this.leagueId);

                if (this.league) {
                    this.$store.dispatch("fetchData",  {filename: "leagues/league_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        this.detailData = res;
                        var season_key = this.league.fmpSeasonId;
                        this.seasonInfo = this.detailData.seasons.find(s => s.fmpSeasonId == season_key);
                        this.team = this.detailData.teams.find(t => t.fmpTeamId == this.teamId);

                    });

                    this.$store.dispatch("fetchData",  {filename: "teams/team_" + this.teamId + "_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        this.teamData = res;
                        this.teamHistory = this.teamData.historie.replaceAll("\n","<br/>");
                        if (this.teamData.venues) {
                            this.teamData.venues.sort((a,b) => (Number(a.isDefault) - Number(b.isDefaul)) || (moment(a.contract_start) - moment(b.contract_start)));
                        }
                    });
                }
            });
        },
        universalMapsLink(contact) {
            let link_part = contact.street + " " + contact.postal_code + " " + contact.city;
            return "http://maps.apple.com/?daddr=" + encodeURIComponent(link_part) + "&dirflg=d&t=m";
        }
    },
    mounted () {
        this.init(this.$route.params.id, this.$route.params.leagueId);
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.params.id, to.params.leagueId);
        next();
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}

</script>

<style lang="scss">
    .history {
        h2 {
            font-size: 22px;
            letter-spacing: -0.46px;

            @media (min-width: $breakpoint-sm) {
                font-size: 24px;
            }
        }

        p {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .plain_link {
        text-decoration: none;
        color: $colorBlack;
    }
</style>

<style lang="scss" scoped>
    .list__header {
        &:not(:last-child) {
            box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
        }
    }

    .list {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    ::v-deep .tabs {
        margin-bottom: 12px !important;
    }
</style>