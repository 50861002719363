<template>

  <div v-if="top3Articles && top3Articles.length > 0">
    <!-- MOBILE -->
    <div class="slider hidden-sm-up">
        <Splide :options="options" :slides="top3Articles">
            <SplideSlide v-for="article in top3Articles" v-bind:key="article.id">
                <NewsCard :article="article" :useSquarePreviewImage="true"/>
            </SplideSlide>
        </Splide>
    </div>

    <!-- Desktop -->
    <div class="grid hidden-xs-down">
        <div class="col-md-4 col-sm-6"  v-for="article in top3Articles" v-bind:key="-article.id">
            <NewsCard :article="article" :key="-article.id" :useSquarePreviewImage="false"/>
        </div>
    </div>
  
  </div>

  <div v-else>
    Keine Artikel gefunden.
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import NewsCard from '@/components/NewsCard.vue';
import moment from 'moment';

export default {
    name: 'HomeNews',
    components: {
        Splide,
        SplideSlide,
        NewsCard
    },
    props: {
        filterLeague: {
            type: Number,
            default() { return -1; } 
        },
        filterTeam: {
            type: Number,
            default() { return -1; } 
        },
    },
    data() {
        return {
            top3Articles: [],
            options: {
                arrows: false,
                pagination: false,
                gap: '8px',
                padding: '24px'
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$store.dispatch('fetchLatestArticles', {limit: 3, filterLeague: this.filterLeague, filterTeam: this.filterTeam}).then((res) => {
                this.top3Articles = res.items;
                this.top3Articles = this.top3Articles.sort((b,a) => {
                    return moment(a.go_live_at || a.meta.first_published_at) - moment(b.go_live_at || b.meta.first_published_at);
                });
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .news-card {
        height: 100%;
    }
</style>