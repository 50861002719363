<template>

    <div v-if="venue" class="stadium">

        <div class="stadium__address">
            <h4>{{ venue.name }}</h4>
            <p v-if="venueAddressField('city')">{{ venueAddressField("street") }}<br>
            {{ venueAddressField("zip") }} {{ venueAddressField("city") }}</p>
            <div class="hidden-sm-down">
                <div class="stadium__meta">
                    <a v-if="universalMapsLink()" :href="universalMapsLink()" target="_blank" class="stadium__meta__pill stadium__meta__pill--highlight">
                        <img src="@/assets/ic_10_route.svg" alt="Pfeil">Route
                    </a>
                    <!-- <div v-if="venueCapacity > 0" class="stadium__meta__pill">
                        {{ venueCapacity }} Kapazität
                    </div> -->
                </div>
            </div>
        </div>
        <div class="hidden-md-up">
            <div class="stadium__meta">
                <a v-if="universalMapsLink()" :href="universalMapsLink()" target="_blank" class="stadium__meta__pill stadium__meta__pill--highlight">
                    <img src="@/assets/ic_10_route.svg" alt="Pfeil">Route
                </a>
                <!-- <div v-if="venueCapacity > 0" class="stadium__meta__pill">
                    {{ venueCapacity }} Kapazität
                </div> -->
            </div>
        </div>
    </div>
    <!-- <a v-if="universalMapsLink()" :href="universalMapsLink()" target="_blank" class="list__item list__item--drilldown">
        <div class="list__item__icon list__item__icon--24">
            <img src="@/assets/ic_24_car.svg" alt="Auto">
        </div>
        <div class="list__item__label">
            Anfahrt
        </div>
    </a> -->
    <div v-if="ticketShop" class="list__item list__item--drilldown">
        <div class="list__item__icon list__item__icon--24">
            <img src="@/assets/ic_24_ticket.svg" alt="Ticket">
        </div>
        <div class="list__item__label">
            <a :href="ticketShop" target="_blank">Tickets</a>
        </div>
    </div>
    <!-- <div class="list__item list__item--drilldown">
        <div class="list__item__icon list__item__icon--24">
            <img src="@/assets/ic_24_handy.svg" alt="Telefon">
        </div>
        <div class="list__item__label">
            Anrufen
        </div>
    </div> -->

</template>
<script>

export default {
    name: 'VenueInformation',
    props: {
        venue: {
            type: Object,
            default() { return null; }
        },
        ticketShop: {
            type: String,
            default() { return null; }
        }
    },
    methods: {
        venueAddressField(f) {
            if (this.venue && this.venue.contact && this.venue.contact.address) {
                return this.venue.contact.address[f];
            }
            else if (this.venue && this.venue.contact) {
                if (f == "zip") {
                    return this.venue.contact.postal_code;
                }
                return this.venue.contact[f];
            }
            return "";
        },
        universalMapsLink() {
            if (this.venueAddressField("city")) {
                let link_part = this.venueAddressField("street") + " " + this.venueAddressField("zip") + " " + this.venueAddressField("city");
                return "http://maps.apple.com/?daddr=" + encodeURIComponent(link_part) + "&dirflg=d&t=m";
            }
            return null;
        }
    },
    computed: {
        venueCapacity() {
            if (this.venue && this.venue.pitches && this.venue.pitches.length > 0) {
                return this.venue.pitches[0].capacity;
            }
            return 0;
        },

    }
}
</script>

<style lang="scss">
    .stadium {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 12px 0 24px;

        @media (min-width: $breakpoint-md) {
            margin: 12px 0 20px;
            align-items: flex-end;
        }

        &__photo {
            display: flex;
            width: 96px;

            @media (min-width: $breakpoint-md) {
                width: 148px;
            }

            img {
                width: 100%;
                height: 74px;
                border-radius: 8px;
                object-fit: cover;

                @media (min-width: $breakpoint-md) {
                    height: 112px;
                }
            }

            ~ .stadium__address {
                width: calc(100% - 96px - 16px);

                @media (min-width: $breakpoint-md) {
                    width: calc(100% - 148px - 16px);
                }
            }
        }

        &__address {
            width: 100%;

            @media (min-width: $breakpoint-sm) {
                margin-top: 12px;
            }

            h4 {
                font-size: 16px;
                line-height: 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin: 0 0 4px 0;
            }

            p {
                margin: 0;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                letter-spacing: -0.33px;
                color: rgba($colorBlack, .48);
            }
        }

        &__meta {
            display: flex;
            width: 100%;
            margin-top: 16px;

            &__pill {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                letter-spacing: -0.24px;
                color: $colorBlack;
                font-weight: 700;
                padding: 4px 10px;
                border-radius: 12px;
                background: rgba($colorBlack, .08);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:not(:last-child) {
                    margin-right: 8px;
                }

                img {
                    margin-right: 4px;
                }

                &--highlight {
                    background: rgba($colorPrimary, .16);
                    color: $colorPrimary;
                    font-weight: 800;
                    text-decoration: none;
                    cursor: pointer;
                    transition: all .2s ease-in-out;

                    &:hover {
                        background: rgba($colorPrimary, .2);
                    }
                }
            }
        }
    }
</style>