<template>
    <div v-if="(windowWidth <= 576)" :id="adBannerId" class="col-12 ad_container"></div>
</template>
<script>

export default {
    name: 'MobileAd',
    data() {
        return {
            windowWidth: null,
        }
    },
    props: {
        adType: {
            type: String,
            default() { return "";} 
        },
    },
    methods: {
        init() {
            this.windowWidth = window.innerWidth;
            window.addEventListener('resize', this.handleResize);
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        adBannerId() {
            return "SPMADS_HBF-MEW_" + this.pageType + "_" + this.adType;
        },
        pageType() {
            if (this.$route.name == "home") { return "HOME"; }
            return "REST";
        }
    }
}

</script>

<style scoped>
.ad_container {
    text-align: center;
    padding: 0!important;
}
</style>