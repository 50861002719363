<template>
  <div :class="matchIsLive ? 'match-card is-live' : 'match-card'">

    <!-- Date & Time -->
    <div class="match-card__header">
      <div class="match-card__header__left">
        {{ matchDayString }}
      </div>
      <div v-if="matchIsLive"  class="match-card__header__right">
        {{ matchLiveData.match_time }}. Minute
      </div>
      <div v-else-if="matchInfo.resultCode == 'postponed'" class="match-card__header__right">
        Verschoben
      </div>
      <div v-else class="match-card__header__right">
        {{ dateString(matchInfo.matchDate) }}・
        {{ timeString(matchInfo.matchDate) }} Uhr
      </div>
    </div>

    <!-- Teams -->
    <div class="match-card__teams">
      <!-- Home -->
      <div class="match-card__teams__item">
        <div class="match-card__teams__item__logo">
          <img :src="teamLogo(matchInfo.fmpTeamHomeId)" :alt="'Logo ' + teamName(matchInfo.fmpTeamHomeId)">
        </div>
        <div class="match-card__teams__item__name">
          {{ teamName(matchInfo.fmpTeamHomeId) }}
        </div>
        <div v-if="matchHasLiveData && (matchLiveData.matchStatus == '2nd_half' || matchLiveData.matchStatus == 'ended')" class="match-card__teams__item__goals">
          <span>{{ matchLiveData.goalsHomeHalftime }}</span>
          <span>{{ matchLiveData.goalsHome }}</span>
        </div>
        <div v-else-if="matchHasLiveData && (matchLiveData.matchStatus == '1st_half' || matchLiveData.matchStatus == 'halftime')" class="match-card__teams__item__goals">
            <span></span><span>{{ matchLiveData.goalsHomeHalftime }}</span>
        </div>
        <div v-else-if="matchIsFinished" class="match-card__teams__item__goals">
          <span>{{ matchInfo.goalsHomeHalftime }}</span>
          <span>{{ matchInfo.goalsHome }}</span>
        </div>
        <div v-else class="match-card__teams__item__goals">
            <span>-</span>
        </div>
      </div>
      <!-- Away -->
      <div class="match-card__teams__item">
        <div class="match-card__teams__item__logo">
          <img :src="teamLogo(matchInfo.fmpTeamAwayId)" :alt="'Logo ' + teamName(matchInfo.fmpTeamAwayId)">
        </div>
        <div class="match-card__teams__item__name">
          {{ teamName(matchInfo.fmpTeamAwayId) }}
        </div>
        <div v-if="matchHasLiveData && (matchLiveData.matchStatus == '2nd_half' || matchLiveData.matchStatus == 'ended')" class="match-card__teams__item__goals">
          <span>{{ matchLiveData.goalsAwayHalftime }}</span>
          <span>{{ matchLiveData.goalsAway }}</span>
        </div>
        <div v-else-if="matchHasLiveData && (matchLiveData.matchStatus == '1st_half' || matchLiveData.matchStatus == 'halftime')" class="match-card__teams__item__goals">
            <span></span><span class="is-live">{{ matchLiveData.goalsAwayHalftime }}</span>
        </div>
        <div v-else-if="matchIsFinished" class="match-card__teams__item__goals">
          <span>{{ matchInfo.goalsAwayHalftime }}</span>
          <span>{{ matchInfo.goalsAway }}</span>
        </div>
        <div v-else class="match-card__teams__item__goals">
            <span>-</span>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="match-card__footer">

      <router-link v-if="linkToLiveStream || matchIsLive || displayLiveTickerAndStream" :to="'/match/' + matchInfo.fmpMatchId + '/' + competition.id + '?activeTab=livestream'" class="match-card__footer__button">
        Livestream
        <img src="@/assets/ic_16_livestream.svg">
      </router-link>
      <router-link v-else :to="'/match/' + matchInfo.fmpMatchId + '/' + competition.id" class="match-card__footer__button">
        Zum Spiel
        <img src="@/assets/ic_12_arrow.svg">
      </router-link>
      <router-link v-if="matchIsLive || displayLiveTickerAndStream" :to="'/match/' + matchInfo.fmpMatchId + '/' + competition.id + '?activeTab=liveticker'" class="match-card__footer__button">
        Liveticker
        <img src="@/assets/ic_16_liveticker.svg">
      </router-link>

    </div>

  </div>
</template>

<script>
import moment from 'moment';
import {teamLogoUrl} from '@/hbf-helper';


export default {
    name: 'MatchCard',
    props: {
        matchInfo: {
            type: Object,
            default() { return {} } 
        },
        teams: {
            type: Array,
            default() { return [] }
        },
        competition: {
            type: Object,
            default() { return {} } 
        },
        linkToLiveStream: {
            type: Boolean,
            default() { return false; } 
        },
    },
    data() {
        return {
            liveData: null,
            matchLiveData: null,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            var now = moment();
            var md = moment(this.matchInfo.matchDate);
            var distance = now.diff(md, 'minutes');
            /* fetch live data if match starts in the next 15 minutes and is max 3 hours after scheduled start */
            /* FIXME: 1 day now for safety */
            if (distance > -15 && distance < 1440) {
                console.log("matchcard fetching livedata...")
                this.$store.dispatch("fetchLiveData").then( () => {
                    this.liveData = this.$store.getters.getLiveData;
                    this.matchLiveData = this.liveData.find(m => m.fmpMatchId == this.matchInfo.fmpMatchId);
                });
            }
        },
        dateString: function (date) {
            return moment(date).locale("de").format('dd., DD.MM.YY');
        },
        timeString: function(date) {
            return moment(date).locale("de").format("HH:mm");
        },
        teamName(fmpTeamId) {
            if (this.teams && fmpTeamId) {
                let team = this.teams.find(el => el.fmpTeamId == fmpTeamId);
                if (this.matchInfo && this.competition && this.competition.competitionType == 2) {
                    if (fmpTeamId == this.matchInfo.fmpTeamHomeId) {
                        return this.matchInfo.webseitennameHome;
                    }
                    else if (fmpTeamId == this.matchInfo.fmpTeamAwayId) {
                        return this.matchInfo.webseitennameAway;
                    }
                }
                return team.teamName;
            }
            return "";   
        },
        teamLogo(fmpTeamId) {
            if (this.teams && fmpTeamId) {
                return teamLogoUrl(this.teams.find(el => el.fmpTeamId == fmpTeamId), 40, this.baseUrl, this.matchInfo.fmpSeasonId);
            }
            return require("@/assets/1x1.png");  
        }
    },
    computed: {
        matchIsLive() {
            if (this.matchLiveData && this.matchLiveData.status == "live" && this.matchLiveData.match_time >= 1) {
                return true;
            }
            return false;
        },
        displayLiveTickerAndStream() {
            if (this.matchInfo.resultCode == 'postponed') { return false; }
            let now = moment();
            let md = moment(this.matchInfo.matchDate);
            let distance = now.diff(md, 'minutes');
            return (distance >= -10 && distance <= 150);

        },
        matchHasLiveData() {
            if (this.matchLiveData) {
                return this.matchLiveData.status == "live" || this.matchLiveData.status == "ended"|| this.matchLiveData.status == "closed";
            }
            return false;
        },
        matchIsFinished() {
            if (!this.matchInfo) { return false; }
            if (moment() < moment(this.matchInfo.matchDate)) { return false; }
            if (this.matchInfo.resultCode == "postponed") { return false; }
            return this.matchInfo.goalsHome !== null && this.matchInfo.goalsAway !== null;
        },
        matchDayString() {
            if (!this.matchInfo) { return ""; }
            if (this.matchInfo._otherCompetition) {
                let c = this.matchInfo._otherCompetition;
                if (c.id == 3) {
                    return "Pokal " + this.matchInfo.matchDay + ". Runde";
                }
                else if (c.id == 4) {
                    return "Super Cup";
                }
            }
            if (this.competition && this.competition.competitionType == 2) {
                return this.matchInfo.matchDay + ". Runde";
            }
            return this.matchInfo.matchDay + ". Spieltag";
        },
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss">
  .match-card {
    background: $colorWhite;
    box-shadow: 0 2px 8px 0 rgba($colorBlack, .02), 0 0 0 1px rgba($colorBlack, .06);
    border-radius: 12px;
    padding: 12px 16px 16px 16px;
    overflow: hidden;
    transition: all .2s ease-in-out;

    &:hover {
      box-shadow: 0 8px 8px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .06);
    }

    &__header {
      display: flex;
      margin-bottom: 7px;

      &__left {
        display: flex;
        font-size: 10px;
        font-weight: 800;
        color: rgba($colorBlack, .48);
        text-transform: uppercase;
      }

      &__right {
        display: flex;
        font-size: 10px;
        font-weight: 800;
        color: rgba($colorBlack, .48);
        text-transform: uppercase;
        margin-left: auto;

        .is-live & {
          color: $colorLive;
          position: relative;
          padding-right: 10px;

          &:after {
            content: '';
            position: absolute;
            top: calc(50% - 4px);
            right: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: $colorLive;
          }
        }
      }
    }

    &__teams {
      display: flex;
      flex-direction: column;

      &__item {
        display: flex;
        align-items: center;
        margin: 1px 0;

        &__logo {
          display: flex;
          width: 20px;
          height: 20px;
          margin-right: 4px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
          }
        }

        &__name {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: -0.25px;
          color: $colorBlack;
          margin-right: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &__goals {
          display: flex;
          align-items: center;

          span {
            display: flex;
            justify-content: center;
            width: 24px;
            font-size: 17px;
            line-height: 27px;
            font-weight: 700;
            color: $colorBlack;

            .is-live & {
              color: $colorLive;
            }

            &:first-of-type {
              width: 16px;
              font-size: 11px;
              color: rgba($colorBlack, .48) !important;
              transform: translateY(1px);
            }
          }
        }
      }
    }

    &__footer {
      display: flex;
      margin: 10px -16px -16px;
      box-shadow: inset 0 1px 0 0 rgba($colorBlack, .08);

      &__button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 12px 0 11px 0;
        font-size: 11px;
        font-weight: 800;
        color: rgba($colorBlack, .48);
        text-transform: uppercase;
        text-decoration: none;
        transition: all .2s ease-in-out;

        .is-live & {
          color: $colorBlack;
          flex-direction: row-reverse;
          position: relative;

          &:last-child {
              &:before {
              content: '';
              position: absolute;
              top: calc(50% - 10px);
              left: 0;
              width: 1px;
              height: 20px;
              background: rgba($colorBlack, .16);
            }
          }
        }

        &:hover {
          background: rgba($colorBlack, .02);
          color: rgba($colorBlack, .64);

          .is-live & {
            color: $colorBlack;
          }
        }

        img {
          margin-left: 5px;
          transform: translateY(-1px);
          opacity: .48;

          .is-live & {
            margin: 0 8px 0 0;
            opacity: 1;
          }
        }
      }
    }
  }
</style>
