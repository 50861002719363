<template>
    <div>
        <!-- Statistik-Kachel mit zusätzlichen Meta-Daten / Spieler -->
        <div v-if="localStatistics && mainKey && mainLabel" class="list">

            <div v-if="localStatistics.length > 0 && localStatistics[0][mainKey] > 0" class="list__highlight">
                <div class="list__highlight__avatar" @click="openProfile(localStatistics[0])">
                    <div class="list__highlight__avatar__badge">
                    <img :src="teamLogo(localStatistics[0].fmpTeamId)" alt="">
                    </div>
                    <img :src="'https://hbf-helper.deinsportplatz.de/images/person/' + localStatistics[0].fmpPlayerId + '/square'" alt="">
                </div>
                <div v-if="statisticsType == 'topScorer'" class="list__highlight__text">
                    <div class="list__highlight__text__score">
                    <div class="list__highlight__text__score__number">
                        {{ localStatistics[0][mainKey] }}<span>{{ getLabel(localStatistics[0][mainKey]) }}</span>
                    </div>
                    <div v-if="mainKey=='goals'" class="list__highlight__text__score__meta">
                        Ø {{ (localStatistics[0][mainKey] / localStatistics[0].totalMatches).toFixed(1) }} / Spiel・7-Meter: {{ localStatistics[0].sevenMeterGoals }}
                    </div>
                    <div v-else-if="!isAverage" class="list__highlight__text__score__meta">
                        Ø {{ (localStatistics[0][mainKey] / localStatistics[0].totalMatches).toFixed(1) }} / Spiel
                    </div>
                    <div v-else class="list__highlight__text__score__meta">
                        7-Meter: {{ (localStatistics[0].sevenMeterGoals / localStatistics[0].totalMatches).toFixed(1) }}
                    </div>
                    </div>
                    <div class="list__highlight__text__name">
                    {{ localStatistics[0].firstName }} {{ localStatistics[0].lastName }}
                    <span>{{ translatePosition(localStatistics[0].position) }}</span>
                    </div>
                </div>
                <div v-else-if="statisticsType == 'topSaves'" class="list__highlight__text">
                    <div class="list__highlight__text__score">
                    <div class="list__highlight__text__score__number">
                        {{ localStatistics[0][mainKey] }}<span>{{ getLabel(localStatistics[0][mainKey]) }}</span>
                    </div>
                    <div v-if="!isAverage" class="list__highlight__text__score__meta">
                        Ø {{ (localStatistics[0][mainKey] / localStatistics[0].totalMatches).toFixed(1) }} / Spiel
                    </div>
                    <div v-else class="list__highlight__text__score__meta">&nbsp;</div>
                    </div>
                    <div class="list__highlight__text__name">
                    {{ localStatistics[0].firstName }} {{ localStatistics[0].lastName }}
                    <span>{{ translatePosition(localStatistics[0].position) }}</span>
                    </div>
                </div>
                <div v-else-if="statisticsType == 'topAssists'" class="list__highlight__text">
                    <div class="list__highlight__text__score">
                    <div class="list__highlight__text__score__number">
                        {{ localStatistics[0][mainKey] }}<span>{{ getLabel(localStatistics[0][mainKey]) }}</span>
                    </div>
                    <div v-if="!isAverage" class="list__highlight__text__score__meta">
                        Ø {{ (localStatistics[0].assists / localStatistics[0].totalMatches).toFixed(1) }} / Spiel
                    </div>
                    <div v-else class="list__highlight__text__score__meta">&nbsp;</div>
                    </div>
                    <div class="list__highlight__text__name">
                    {{ localStatistics[0].firstName }} {{ localStatistics[0].lastName }}
                    <span>{{ translatePosition(localStatistics[0].position) }}</span>
                    </div>
                </div>
                <div v-else-if="statisticsType == 'topSinners'" class="list__highlight__text">
                    <div class="list__highlight__text__score">
                    <div class="list__highlight__text__score__number">
                        {{ localStatistics[0][mainKey] }}<span>{{ getLabel(localStatistics[0][mainKey]) }}</span>
                    </div>
                    <div class="list__highlight__text__score__meta">
                        {{ localStatistics[0].totalMatches }} {{ singularPlural(localStatistics[0].totalMatches, "Spiel", "Spiele") }}
                    </div>
                    </div>
                    <div class="list__highlight__text__name">
                    {{ localStatistics[0].firstName }} {{ localStatistics[0].lastName }}
                    <span>{{ translatePosition(localStatistics[0].position) }}</span>
                    </div>
                </div>
            </div>

            <template v-for="p, idx in localStatistics.slice(1, limit)" >
                <div v-if="p[mainKey] > 0" v-bind:key="p.fmpPlayerId" class="list__item">
                    <div  class="list__item__label" @click="openProfile(p)">
                        <div class="list__item__number" v-bind:class="idx > 99 ? 'large-number' : ''">
                        {{ idx+2 }}
                        </div>
                        <div class="list__item__icon list__item__icon--16 list__item__icon--avatar">
                        <img :src="'https://hbf-helper.deinsportplatz.de/images/person/' + p.fmpPlayerId + '/square'" alt="">
                        </div>
                        <span>{{ p.firstName }} {{ p.lastName }}</span>
                    </div>
                    <div class="list__item__value">
                        {{ p[mainKey] }} {{ getLabel(p[mainKey]) }}
                    </div>
                </div>
            </template>

            <div v-if="limit < statisticsLength">
                <button v-if="limit < 30" class="load-more" @click="limit = limit + 10">
                    <img src="@/assets/ic_12_arrow.svg">
                    Mehr anzeigen
                </button>
                <button v-else class="load-more" @click="limit = statisticsLength">
                    <img src="@/assets/ic_12_arrow.svg">
                    Alles anzeigen
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import {translatePosition, slugify} from '@/hbf-helper';

export default {
    name: 'PlayerStatisticsTile',
    props: {
        data: {
            type: Array,
            default() { return []; } 
        },
        statisticsType: {
            type: String,
            default() { return "topScorer"; }
        },
        mainKey: {
            type: String,
            default() { return null; }
        },
        key2: {
            type: String,
            default() { return null; }
        },
        key3: {
            type: String,
            default() { return null; }
        },
        mainLabel: {
            type: String,
            default() { return null; }
        },
        competitionId: {
            type: Number,
            default() { return -1; }
        }
    },
    created() {
        this.translatePosition = translatePosition;
    },
    data() {
        return {
            localStatistics: [...this.data],
            labelSingular: null,
            labelPlural: null,
            limit: 10,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (this.key3 && this.key2 && this.mainKey) {
                this.localStatistics.sort((s1, s2) => s2[this.mainKey] - s1[this.mainKey] || s2[this.key2] - s1[this.key2] || s2[this.key3] - s1[this.key3]);
            }
            else if (this.key2 && this.mainKey) {
                this.localStatistics.sort((s1, s2) => s2[this.mainKey] - s1[this.mainKey] || s2[this.key2] - s1[this.key2]);
            }
            else if (this.mainKey) {
                this.localStatistics.sort((s1, s2) => s2[this.mainKey] - s1[this.mainKey]);
            }
            if (this.mainLabel) {
                let labels = this.mainLabel.split("|");
                if (labels.length >= 2) {
                    this.labelSingular = labels[0];
                    this.labelPlural = labels[1];
                }
                else {
                    this.labelSingular = this.labelPlural = this.mainLabel;
                }
            }
        },
        singularPlural(cnt, s, p) {
            if (cnt == 1) { return s; }
            return p;
        },
        teamLogo(teamId) {
            return this.baseUrl + "/fmp/teamlogo/40/" + teamId;
        },
        openProfile(s) {
            if (!s) { return; }
            var profileUrl = "/profile/" + s.fmpPlayerId + "--" + slugify(s.firstName + "-" + s.lastName) + "/" + this.competitionId + "/" + s.fmpTeamId;
            this.$router.push(profileUrl);
        },
        getLabel(count) {
            if (count == 1) { return this.labelSingular; }
            return this.labelPlural;
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        },
        isAverage() {
            return this.mainKey.startsWith("_avg") || this.mainKey.startsWith("avg");
        },
        statisticsLength() {
            if (this.mainKey && this.localStatistics) {
                let arr = this.localStatistics.filter(s => s[this.mainKey] > 0);
                return arr.length;
            }
            return 0;
        }   
    }
}
</script>

<style lang="scss">
    .large-number {
        width: 20px;
    }
    .list__highlight__avatar, .list__item__label {
        cursor: pointer;
    }
</style>
