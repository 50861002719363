<template>

  <div class="rankingPreview">

    <div v-for="t in tableExcerpt" v-bind:key="t.id" :class="rankingPreviewClass(t)">
      <div class="rankingPreview__item__position">
        {{ t.position }}
      </div>
      <div class="rankingPreview__item__logo">
        <img :src="teamLogo(t)" :alt="'Logo ' + t.teamName">
      </div>
      <div class="rankingPreview__item__name">
        {{ t.teamName }}
      </div>
      <div class="rankingPreview__item__points">
        {{ t.points }}
      </div>
    </div>

  </div>

</template>

<script>
import {teamLogoUrl} from '@/hbf-helper';

export default {
    name: 'RankingPreviewTeam',
    mounted() {
          this.init();
     },
     data() {
          return {
               table: null,
               teamRanking: null,
               position: 0,
               tableExcerpt: null,
          }
     },
     methods: {
        init() {
            this.$store.dispatch("fetchData",  {filename: "tables/table_" + this.seasonId + ".json"}).then( (res) => {
                this.table = res.table;
                this.teamRanking = this.table.find(t => t.fmpTeamId == this.teamId);
                if (this.teamRanking) {
                    this.position = this.teamRanking.position;
                    let startPosition = Math.min(this.table.length - 4, Math.max(1, this.position - 2)) - 1;
                    let endPosition = Math.max(5, Math.min(this.table.length, this.position + 2));
                    this.tableExcerpt = this.table.slice(startPosition, endPosition);
                }
            });
        },
        rankingPreviewClass(te) {
            var cl = ["rankingPreview__item"];
            let variants = ["rankingPreview__item--lighter48", "rankingPreview__item--lighter40",
                "rankingPreview__item--lighter32", "rankingPreview__item--lighter24"];

            let distance = Math.abs(te.position - this.position);

            if (te.position == this.position && this.teamId == te.fmpTeamId) {
                cl.push("rankingPreview__item--highlight");
            }
            /* equally ranked team */
            else if (te.position == this.position && this.teamId != te.fmpTeamId) {
                cl.push(variants[0]);
            }
            else if (this.position == 1 || this.position == this.table.length) {
                cl.push(variants[distance - 1]);
            }
            else if (this.position == 2 || this.position == this.table.length-1) {
                cl.push(variants[distance]);
            }
            else if (this.position >= 3 && distance == 1) {
                cl.push("rankingPreview__item--lighter48");
            }
            else if (this.position >= 3 && distance == 2) {
                cl.push("rankingPreview__item--lighter32");
            }

            return cl.join(" ");
        },
        teamLogo(team) {
            return teamLogoUrl(team, 40, this.baseUrl, this.seasonId);
        }
    },
    props: {
        competitionId: {
            type: Number,
            default() { return -1; } 
        },
        seasonId: {
            type: Number,
            default() { return -1; } 
        },
        teamId: {
            type: Number,
            default() { return -1; }      
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss" scoped>

    .rankingPreview {
        @media (min-width: $breakpoint-sm) {
            padding: 0 16px;
        }
    }

    .rankingPreview__item {

        @media (min-width: $breakpoint-sm) {

            &:nth-child(1), &:nth-child(2) {
                margin-bottom: -4px;
            }

            &--highlight {
                &:before {
                    height: 28px;
                    top: 2px;
                }
            }

            &:nth-child(4), &:nth-child(5) {
                margin-top: -4px;
            }

        }

        &--lighter48 {
            opacity: .48;
        }

        &--lighter40 {
            opacity: .4;
        }

        &--lighter32 {
            opacity: .32;
        }

        &--lighter24 {
            opacity: .24;
        }
    }
</style>
