<template>
    <div v-if="league && team" class="team">

        <TeamSubNavigation :team="team" :league="league" :baseUrl="baseUrl"></TeamSubNavigation>

        <!-- Team Portraits -->
        <div class="team__portraits">
            <div class="container">
                <div v-if="teamSquad" class="team__portraits__content">
                    <div v-for="s in teamSquad" v-bind:key="s.player.fmpPlayerId" class="team__portraits__item" @click="openProfile(s)" :data-tooltip="s.player.firstName.charAt(0) + '. ' + s.player.lastName">
                        <div v-if="s.player.captain" class="team__portraits__item__badge">
                            C
                        </div>
                        <div class="team__portraits__item__image">
                            <img :src="'https://hbf-helper.deinsportplatz.de/images/contract/' + s.fmpContractId + '/' + s.player.fmpPlayerId + '/square/' + league.fmpSeasonId" alt="">
                        </div>
                    </div>
                    <router-link :to="'/team/' + team.fmpTeamId + '/kader/' + league.id" class="team__portraits__item team__portraits__item--more">
                        <img src="@/assets/player/player-portrait-more.svg" alt="">
                    </router-link>
                </div>
            </div>
        </div>

        <DisplayAd adType="Billboard"></DisplayAd>
        <MobileAd adType="Mobile_Ad"></MobileAd>

        <div v-if="dataReady" class="container">
            <div class="grid">

                <div class="col-12">
                    <div class="grid">

                        <!-- Aktuelle Spiele -->
                        <div class="col-sm-6 col-md-8">
                            <div class="section">
                                <div class="section__title">
                                    <h2>Aktuelle Spiele</h2>
                                    <router-link :to="'/team/' + teamSlug(team) + '/spielplan/' + league.id" class="section__title__more">
                                        Alle Spiele
                                    </router-link>
                                </div>
                                <div class="grid">

                                    <!-- MOBILE -->
                                    <div class="slider col-12 hidden-sm-up">
                                    <Splide :options="options" :slides="allTeamMatches">
                                        <SplideSlide v-for="m in allTeamMatches" v-bind:key="m.fmpMatchId" >
                                            <MatchCard :matchInfo="m" :teams="fullTeamList" :competition="m._otherCompetition ? m._otherCompetition : league"/>
                                        </SplideSlide>
                                    </Splide>
                                    </div>

                                    <!-- Desktop -->
                                    <div class="col-sm-12 col-md-6 hidden-xs-down">
                                            <MatchCard v-if="mostRecentMatchesForTeamCount > 0" :matchInfo="mostRecentTeamMatches[0]" :teams="fullTeamList" :competition="mostRecentTeamMatches[0]._otherCompetition ? mostRecentTeamMatches[0]._otherCompetition : league"/>
                                    </div>
                                    <div class="col-sm-6 hidden-sm-down ">
                                        <MatchCard v-if="mostRecentMatchesForTeamCount > 1" :matchInfo="mostRecentTeamMatches[1]" :teams="fullTeamList" :competition="mostRecentTeamMatches[1]._otherCompetition ? mostRecentTeamMatches[1]._otherCompetition : league"/>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <!-- Tabelle -->
                        <div class="col-sm-6 col-md-4">
                            <div class="section">
                                <div class="section__title">
                                    <h2>Tabelle</h2>
                                    <router-link :to="'/team/' + teamSlug(team) + '/tabelle/' + league.id" class="section__title__more">
                                        Details
                                    </router-link>
                                </div>
                                <RankingPreviewTeam :seasonId="league.fmpSeasonId" :teamId="teamId"/>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- News -->
                <div v-if="cmsTeam" class="col-12">
                    <div class="section">
                        <div class="section__title">
                            <h2>News</h2>
                            <router-link :to="'/team/' + teamSlug(team) + '/news/' + league.id" class="section__title__more">
                                Mehr News
                            </router-link>
                        </div>
                        <HomeNews :filterTeam="cmsTeam.id"/>
                    </div>
                </div>

                <MobileAd adType="Mobile_MR"></MobileAd>
                <DisplayAd adType="QualityFooter"></DisplayAd>

                <!-- Statistiken -->
                <div v-if="league" class="col-12">
                    <div class="section">
                        <div class="section__title">
                            <h2>Top-Spielerinnen</h2>
                            <router-link :to="'/team/' + teamSlug(team) + '/statistiken/' + league.id" class="section__title__more">
                                Alle Statistiken
                            </router-link>
                        </div>
                        <HomeStatistics :teamId="teamId" :seasonId="league.fmpSeasonId" :competitionId="league.id"></HomeStatistics>
                    </div>
                </div>

            </div> <!-- Close .grid -->
        </div> <!-- Close .container -->

    </div> <!-- Close .team -->
    <div v-else class="team container">
        Zu diesem Team gibt es keine weiteren Informationen.
    </div>
</template>

<script>
import RankingPreviewTeam from '@/components/RankingPreviewTeam.vue'
import HomeNews from '@/components/HomeNews.vue';
import HomeStatistics from '@/components/HomeStatistics.vue';
import MatchCard from '@/components/MatchCard.vue'
import TeamSubNavigation from '@/components/TeamSubNavigation.vue';
import { slugify, shuffleArray, transformRelatedSeasonId } from '@/hbf-helper';
import moment from 'moment';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import MobileAd from "@/components/MobileAd.vue";
import DisplayAd from "@/components/DisplayAd.vue";

export default {
    name: 'TeamView',
    components: {
        TeamSubNavigation,
        RankingPreviewTeam,
        MatchCard,
        HomeNews,
        HomeStatistics,
        Splide,
        SplideSlide,
        MobileAd,
        DisplayAd,
    },
    data() {
        return {
            teamId: null,
            leagueId: null,
            competitions: null,
            league: null,
            detailData: null,
            team: null,
            //teamData: null,
            seasonInfo: null,
            teamSquad: null,
            cmsTeamList: null,
            cmsTeam: null,
            furtherMatches: [],
            furtherTeams: [],
            options: {
                arrows: false,
                pagination: false,
                gap: '8px',
                padding: '24px',
                start: 0,
            },
            dataReady: false,
        };
    },
    methods: {
        init(teamId, leagueId) {
            this.teamId = parseInt(teamId);
            this.leagueId = parseInt(leagueId);

            this.$store.dispatch("fetchTeams").then( () => {
                this.cmsTeamList = this.$store.getters.getTeams;
                this.cmsTeam = this.cmsTeamList.find(t => t.fmpForeignId == this.teamId);
            });

            this.$store.dispatch("fetchCompetitions").then( () => {
                this.competitions = this.$store.getters.getCompetitions;
                this.league = this.competitions.find(el => el.id == this.leagueId);

                if (this.league && this.league.competitionType == 1) {
                    this.$store.dispatch("fetchData",  {filename: "leagues/league_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                        this.detailData = res;
                        var season_key = this.league.fmpSeasonId;
                        this.seasonInfo = this.detailData.seasons.find(s => s.fmpSeasonId == season_key);

                        this.$store.dispatch("fetchData",  {filename: "teams/team_" + this.teamId + "_" + this.league.fmpSeasonId + ".json"}).then( (res) => {
                            this.team = res;
                            if (!this.team) {
                                this.attemptFindTeam();
                                return;
                            }
                            let squad = [];

                            let years = this.seasonInfo.year.split("/");

                            let seasonStart = moment( years[0] + "-07-01");
                            let seasonEnd = moment( years[1] + "-06-30");
                            let transferWindowEnd = moment( years[1] + "-05-30");

                            if (this.team.contracts.past) {
                                squad = this.team.contracts.past;
                            }
                            if (this.team.contracts.current) {
                                squad = squad.concat(this.team.contracts.current);
                            }

                            var now = moment();
                            squad = squad.filter(s => moment(s.start) < seasonEnd && moment(s.start) < now && moment(s.end) > seasonStart && moment(s.end) >= transferWindowEnd);

                            let captain = squad.find(s => s.player.captain);
                            squad = squad.filter(s => !s.player.captain);
                            this.teamSquad = shuffleArray(squad);
                            if (captain) {
                                this.teamSquad.unshift(captain);
                            }

                            /* load further matches from cups / relegation */
                            var reqs = [];

                            this.competitions.filter(c => c.competitionType != 1).forEach(c => {
                                let csi = transformRelatedSeasonId(this.league.id, c.id, c.fmpSeasonId);
                                if (!csi) { return; }
                                reqs.push(this.$store.dispatch("fetchData",  {filename: "leagues/league_" + csi + ".json"}).then( (res) => {
                                    let detailData = res;
                                    let furtherMatches = detailData.schedule.filter(m => m.fmpTeamHomeId == this.teamId || m.fmpTeamAwayId == this.teamId);
                                    if (furtherMatches) {
                                        var now = moment();
                                        furtherMatches.forEach(m => {
                                            m._otherCompetition = c;
                                            m._daysAgo = now.diff(moment(m.matchDate), 'days');
                                        });
                                        this.furtherMatches = this.furtherMatches.concat(furtherMatches);
                                        this.furtherTeams = this.furtherTeams.concat(detailData.teams);
                                    }
                                }));
                            });

                            Promise.all(reqs).then( () => {
                                let mrm = this.firstMatch;
                                if (mrm) {
                                    var idx = this.allTeamMatches.findIndex(m => m.fmpMatchId == mrm.fmpMatchId);
                                    this.options.start = idx;
                                }
                                this.dataReady = true;
                            });

                        });

                        var now = moment();
                        this.detailData.schedule.forEach(el => {
                            el._daysAgo = now.diff(moment(el.matchDate), 'days');
                        });

                    });

                }
                else {
                    this.attemptFindTeam();
                }
            });
        },
        attemptFindTeam() {
            console.log("looking for team in all competitions...");
            this.competitions.filter(c => c.competitionType == 1).some(c => {
                console.log(c.name)
                this.$store.dispatch("fetchData", {filename: "leagues/league_" + c.fmpSeasonId + ".json"}).then( (res) => {
                    let detailData = res;
                    let team = detailData.teams.find(t => t.fmpTeamId == this.teamId);
                    if (team) {
                        console.log("found " + team.teamName + " in " + c.name);
                        this.init(this.teamId, c.id);
                        return true;
                    }
                });
            });
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        },
        openProfile(s) {
            if (!s || !s.player) { return; }
            var profileUrl = "/profile/" + s.player.fmpPlayerId + "--" + slugify(s.player.firstName + "-" + s.player.lastName) + "/" + this.leagueId + "/" + this.teamId;
            this.$router.push(profileUrl);
        },
    },
    mounted () {
        this.init(this.$route.params.id, this.$route.params.leagueId);
    },
    beforeRouteUpdate (to, from, next) {
        this.init(to.params.id, to.params.leagueId);
        next();
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        },
        fullTeamList() {
            var teams = [];
            if (this.detailData && this.detailData.teams) {
                teams = this.detailData.teams;
            }
            if (this.furtherTeams && this.furtherTeams.length > 0) {
                teams = teams.concat(this.furtherTeams);
            }
            return teams;
        },
        allTeamMatches() {
            var teamMatches = [];
            if (this.detailData && this.detailData.schedule) {
                teamMatches = this.detailData.schedule.filter(m => m.fmpTeamHomeId == this.teamId || m.fmpTeamAwayId == this.teamId);
            }
            if (this.furtherMatches && this.furtherMatches.length > 0) {
                teamMatches = teamMatches.concat(this.furtherMatches);
            }
            return teamMatches.sort((a, b) => { return moment(a.matchDate) - moment(b.matchDate) });
        },
        mostRecentTeamMatches() {
            var teamMatches = [];
            if (this.detailData && this.detailData.schedule) {
                teamMatches = this.detailData.schedule.filter(m => m.fmpTeamHomeId == this.teamId || m.fmpTeamAwayId == this.teamId);
            }
            if (this.furtherMatches && this.furtherMatches.length > 0) {
                teamMatches = teamMatches.concat(this.furtherMatches);
            }
            return teamMatches.sort((a,b) => { return Math.abs(a._daysAgo) - Math.abs(b._daysAgo) || moment(a.matchDate) - moment(b.matchDate); });
        },
        mostRecentMatchesForTeamCount() {
            return this.mostRecentTeamMatches.length;
        },
        firstMatch() {
            let m = this.mostRecentTeamMatches;
            if (m && m.length > 0) { return m[0]; }
            return null;
        }
    }
}
</script>

<style lang="scss">
    .team {
        &__photo {
            height: 180px;
            width: calc(100% + 48px);
            margin: -16px -24px 0;
            overflow: hidden;

            @media (min-width: 414px) {
                height: 200px;
            }

            @media (min-width: 480px) {
                height: 220px;
            }

            @media (min-width: $breakpoint-xs) {
                height: 240px;
                width: 100%;
                margin: 0;
                border-radius: 8px;
            }

            @media (min-width: $breakpoint-sm) {
                height: 340px;
            }

            @media (min-width: $breakpoint-md) {
                height: 440px;
            }

            @media (min-width: $breakpoint-lg) {
                height: 540px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__title {
            display: flex;
            align-items: center;
            padding: 20px 0 16px;

            @media (min-width: $breakpoint-sm) {
                padding: 40px 0 36px;
            }

            &__logo {
                display: flex;
                width: 56px;
                height: 56px;
                margin-right: 12px;
                object-fit: contain;

                @media (min-width: $breakpoint-sm) {
                    width: 64px;
                    height: 64px;
                    margin-right: 16px;
                }
            }

            &__text {
                display: flex;
                flex-direction: column;

                h1 {
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: -0.48px;
                    margin: 0 0 -4px 0;

                    @media (min-width: $breakpoint-sm) {
                        font-size: 24px;
                        line-height: 32px;
                        letter-spacing: -0.57px;
                        margin: 0 0 -2px 0;
                    }
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                    color: rgba($colorBlack, .48);
                }
            }

            &__facts {
                display: none;

                @media (min-width: $breakpoint-sm) {
                    display: flex;
                    margin-left: auto;
                }

                &__item {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    min-width: 96px;
                    padding: 0 16px;

                    &:not(:last-child) {
                        box-shadow: 1px 0 0 0 rgba($colorBlack, .08);
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    &__value {
                        font-size: 25px;
                        line-height: 25px;
                        font-weight: 800;
                        color: $colorBlack;
                    }

                    &__label {
                        font-size: 10px;
                        line-height: 10px;
                        font-weight: 800;
                        color: rgba($colorBlack, .48);
                        text-transform: uppercase;
                        margin-top: 7px;
                    }
                }
            }
        }

        &__portraits {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                width: 32px;
                height: 100%;
                background-image: linear-gradient(270deg, $colorWhite 0%, rgba($colorWhite, 0) 100%);
            }

            &__content {
                position: relative;
                display: flex;
                width: calc(100% + 48px);
                margin: 32px -24px 12px;
                padding: 0 24px;
                overflow-x: auto;
                overflow-y: hidden;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    right: -20px;
                    width: 20px;
                    height: 1px;
                }

                @media (min-width: $breakpoint-xs) {
                    width: 100%;
                    margin: 48px 0 12px;
                    padding: 0;
                    justify-content: space-between;
                    overflow: visible;
                }
            }

            &__item {
                display: inline-block;
                flex-shrink: 0;
                position: relative;
                width: 64px;
                height: 64px;
                border-radius: 50%;
                margin-right: 8px;
                cursor: pointer;
                transition: all .2s ease-in-out;

                @media (min-width: $breakpoint-xs) {
                    width: 80px;
                    height: 80px;
                    margin-right: 0;

                    &:nth-child(n+6) {
                        display: none;
                    }

                    &:hover {
                        transform: translateY(-2px);
                        box-shadow: 0 6px 8px 0 rgba($colorBlack,0.04), inset 0 0 0 1px rgba($colorBlack, .06);
                    }
                }

                @media (min-width: $breakpoint-sm) {
                    &:nth-child(n+6) {
                        display: flex;
                    }

                    &:nth-child(n+8) {
                        display: none;
                    }
                }

                @media (min-width: $breakpoint-md) {
                    &:nth-child(n+8) {
                        display: flex;
                    }

                    &:nth-child(n+10) {
                        display: none;
                    }
                }

                @media (min-width: $breakpoint-lg) {
                    &:nth-child(n+10) {
                        display: flex;
                    }

                    &:nth-child(n+12) {
                        display: none;
                    }
                }

                &:last-child {
                    display: none;

                    @media (min-width: $breakpoint-xs) {
                        display: flex;
                    }
                }

                &__image {
                    display: flex;
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        box-shadow: inset 0 0 0 1.5px rgba($colorBlack, .04);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                &__badge {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $colorWhite;
                    box-shadow: inset 0 0 0 1px rgba($colorBlack, .12);
                    border-radius: 50%;
                    font-size: 11px;
                    font-weight: 900;
                    color: $colorBlack;
                    width: 20px;
                    height: 20px;
                    right: 0;
                    bottom: 0;

                    @media (min-width: $breakpoint-sm) {
                        width: 24px;
                        height: 24px;
                        right: 1px;
                        bottom: 1px;
                        font-size: 12px;
                    }
                }

                &--more {
                    background: $colorWhite;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: auto;
                        height: auto;
                        opacity: .48;
                    }
                }
            }
        }
    }
</style>