import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';

const store = createStore({
    plugins: [createPersistedState({storage: window.localStorage})],
    state: {
        apiUrl: "https://hbf-cms.deinsportplatz.de/api/v2/",
        baseUrl: "https://hbf-cms.deinsportplatz.de",
        articles: null,
        competitions: null,
        teams: null,
        adbanners: null,
        liveData: null,
        _liveDataFetchInProgress: false,
    },
    getters: {
        getApiUrl(state) {
            return state.apiUrl;
        },
        getBaseUrl(state) {
            return state.baseUrl;
        },
        getArticles(state) {
            if (state.articles !== null) {
                var items = [];
                state.articles.items_json.forEach(el => items.push(JSON.parse(el)));
                return items;
            }
            return null;
        },
        getCompetitions(state) {
            if (state.competitions !== null) {
                var competitions = [];
                state.competitions.items_json.forEach(el => {
                    var comp = JSON.parse(el);
                    if (window._hbf_season_override && window._hbf_season_override[comp.id]) {
                        comp.fmpSeasonId = window._hbf_season_override[comp.id];
                    }
                    competitions.push(comp);
                });
                return competitions;
            }
        },
        getTeams(state) {
            if (state.teams !== null) {
                var teams = [];
                state.teams.items_json.forEach(el => teams.push(JSON.parse(el)));
                return teams;
            }
        },
        getAdBanners(state) {
            if (state.adbanners !== null) {
                var adbanners = [];
                state.adbanners.items_json.forEach(el => adbanners.push(JSON.parse(el)));
                return adbanners;
            }
        },
        getLiveData(state) {
            if (state.liveData !== null) {
                var liveData = [];
                state.liveData.items_json.forEach(el => liveData.push(JSON.parse(el)));
                return liveData;
            }
        }
    },
    mutations: {
        SET_ARTICLES(state, payload) {
            state.articles = payload;
        },
        SET_COMPETITIONS(state, payload) {
            state.competitions = payload;
        },
        SET_TEAMS(state, payload) {
            state.teams = payload;
        },
        SET_ADBANNERS(state, payload) {
            state.adbanners = payload;
        },
        SET_LIVE_DATA(state, payload) {
            state.liveData = payload;
        }
    },
    actions: {
        async fetchArticles({ state, commit }) {
            try {
                if (state.articles != null && state.articles._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.articles._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { 
                        return;
                    }
                }
                console.log("retrieve articles from server");
                let query = {
                    type: "blog.blogPage",
                    fields: "title,intro,image,competitions,teams,go_live_at,preview_image_url,square_preview_image_url",
                    limit: 1000,
                    order: "-first_published_at"};
                let blog_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+ blog_url, {
                    params: query
                });
                var payload = {_lastUpdate: Date.now()};
                payload.items_json = [];
                response.data.items.forEach(el => { payload.items_json.push(JSON.stringify(el))});
                commit('SET_ARTICLES', payload);
            } catch (error) {
                commit('SET_ARTICLES', []);
            }
        },
        async fetchCompetitions({ state, commit }) {
            try {
                if (state.competitions != null && state.competitions._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.competitions._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { 
                        return;
                    }
                }
                console.log("retrieve competitions from server");
                let blog_url = "competitions/?enabled=true"
                let response = await axios.get(`${state.apiUrl}`+ blog_url);
                var payload = {_lastUpdate: Date.now()};
                payload.items_json = [];
                response.data.items.forEach(el => { payload.items_json.push(JSON.stringify(el))});
                commit('SET_COMPETITIONS', payload);
            } catch (error) {
                commit('SET_COMPETITIONS', []);
            }
        },
        async fetchTeams({ state, commit }) {
            try {
                if (state.teams != null && state.teams._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.teams._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { 
                        return;
                    }
                }
                console.log("retrieve teams from server");
                let blog_url = "teams/?limit=100";
                let response = await axios.get(`${state.apiUrl}`+ blog_url);
                var payload = {_lastUpdate: Date.now()};
                payload.items_json = [];
                response.data.items.forEach(el => { payload.items_json.push(JSON.stringify(el))});
                commit('SET_TEAMS', payload);
            } catch (error) {
                commit('SET_TEAMS', []);
            }
        },
        async fetchAdBanners({ state, commit }) {
            try {
                if (state.adbanners != null && state.adbanners._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.adbanners._lastUpdate) / 1000;
                    if (ageInSeconds < 300) { 
                        return;
                    }
                }
                console.log("retrieve adbanners from server");
                let adbanners_url = "adbanners/?is_active=true&limit=100";
                let response = await axios.get(`${state.apiUrl}`+ adbanners_url);
                var payload = {_lastUpdate: Date.now()};
                payload.items_json = [];
                response.data.items.forEach(el => { payload.items_json.push(JSON.stringify(el))});
                commit('SET_ADBANNERS', payload);
            } catch (error) {
                commit('SET_ADBANNERS', []);
            }
        },
        async fetchLiveData({ state, commit }) {
            try {
                if (state.liveData != null && state.liveData._lastUpdate) {
                    let currentTime = Date.now()
                    let ageInSeconds = (currentTime - state.liveData._lastUpdate) / 1000;
                    if (ageInSeconds < 15) { 
                        return;
                    }
                }
                console.log("retrieve live data from server");
                let response = await axios.get(`${state.baseUrl}`+ "/data/live/live.json");

                var payload = {_lastUpdate: Date.now()};
                payload.items_json = [];
                response.data.matches.forEach(el => { payload.items_json.push(JSON.stringify(el))});
                commit('SET_LIVE_DATA', payload);
            } catch (error) {
                commit('SET_LIVE_DATA', []);
            }
        },
        async fetchHomePageInfo({state}) {
            try {
                let response = await axios.get(`${state.apiUrl}`+ "pages/?type=home.homePage&fields=featured_articles,active_match_calendar_tab,featured_competition,search_description,seo_title");
                return response.data;
            } catch(error) {
                console.log("error fetching home page: " + error);
            }
        },
        async searchArticles({state}, {searchTerm}) {
            try {
                let response = await axios.get(`${state.apiUrl}`+ "pages/?type=blog.BlogPage&fields=title,intro,image,go_live_at,preview_image_url,square_preview_image_url&order=-first_published_at&search_operator=and&search=" + searchTerm);
                return response.data;
            } catch(error) {
                console.log("error searching: " + error);
            }
        },
        async fetchArticlesForMatch({state}, {fmpMatchId}) {
            try {
                let response = await axios.get(`${state.apiUrl}`+ "pages/?type=blog.BlogPage&fields=title,intro,image,go_live_at,preview_image_url,square_preview_image_url&match_id=" + fmpMatchId);
                return response.data;
            } catch(error) {
                console.log("error fetching article for match: " + error);
            }
        },
        async fetchLatestArticles({state}, {filterLeague, filterTeam, limit}) {
            try {
                if (limit == null) {
                    limit = 3;
                }
                let query = {
                    type: "blog.blogPage",
                    fields: "title,intro,image,competitions,teams,go_live_at,preview_image_url,square_preview_image_url",
                    limit: limit,
                    order: "-go_live_at"};
                if (filterLeague && filterLeague != -1) {
                    query.competitions = filterLeague;
                }
                if (filterTeam && filterTeam != -1) {
                    query.teams = filterTeam;
                }
                if (filterTeam == -1 && filterLeague == -1) {
                    query.show_on_homepage = true;
                }
                let blog_url = "pages/"
                let response = await axios.get(`${state.apiUrl}`+ blog_url, {
                    params: query
                });
                return response.data;
            } catch(error) {
                console.log("error fetching latest articles: " + error);
            }
        },
        async fetchData({state}, {filename}) {
            try {
                let response = await axios.get(`${state.baseUrl}`+ "/data/" + filename);
                return response.data;
            } catch (error) {
                console.log("error fetching data");
            }
        },
        async fetchFile({state}, {filename}) {
            try {
                let response = await axios.get(`${state.baseUrl}`+ "/" + filename);
                return response.data;
            } catch (error) {
                console.log("error fetching data");
            }
        }
    },
    modules: {
    }
});

export default store;