<template>
    <div>
        <!-- Header -->
        <div class="league__header">
            <div class="container">
                <div class="grid">
                    <div class="col-12">
                        <h1>{{ league.name }}</h1>
                        <!-- <span v-if="season" >Saison {{ season.year }}</span> -->
                        <select v-model="selectedSeasonId" @change="setNewSeason">
                            <option v-for="s in availableSeasons" v-bind:key="s.fmpSeasonId" :value="s.fmpSeasonId">Saison {{ s.year }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <img :src="baseUrl + league.image.meta.download_url" class="flex-img">
        </div>

        <!-- Subnavigation -->
        <div class="tabs" id="tabs" v-bind:class="removeMargin ? 'tabs--nomargin' : ''">
            <div class="container">
                <div class="tabs__content">
                    <router-link :to="'/liga/' + leagueSlug(league)" :class="navigationClasses('/liga/' + leagueSlug(league))">
                        Überblick
                    </router-link>
                    <router-link :to="'/liga/' + leagueSlug(league) + '/spielplan'" :class="navigationClasses('/spielplan')">
                        Spielplan
                    </router-link>
                    <router-link :to="'/liga/' + leagueSlug(league) + '/tabelle'" v-if="league.competitionType == 1" :class="navigationClasses('/tabelle')">
                        Tabelle
                    </router-link>
                    <router-link v-if="league.competitionType == 1" :to="'/liga/' + leagueSlug(league) + '/statistiken'" :class="navigationClasses('/statistiken')">
                        Statistiken
                    </router-link>
                    <router-link :to="'/liga/' + leagueSlug(league) + '/livestreams'" :class="navigationClasses('/livestreams')">
                        Livestreams
                    </router-link>
                    <router-link :to="'/liga/' + leagueSlug(league) + '/liveticker'" :class="navigationClasses('/liveticker')">
                        Liveticker
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { slugify } from '@/hbf-helper';

export default {
    name: 'LeagueSubNavigation',
    props: {
        league: {
            type: Object,
            default() { null; } 
        },
        season: {
            type: Object,
            default() { null; } 
        },
        otherSeasons: {
            type: Array,
            default() { []; } 
        },
        baseUrl: {
            type: String,
            default() { return ""; }
        },
        removeMargin: {
            type: Boolean,
        }
    },
    data() {
        return {
            selectedSeasonId: null,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        setNewSeason() {
            window._hbf_season_override = window._hbf_season_override || {};
            window._hbf_season_override[this.league.id] = parseInt(this.selectedSeasonId);
            console.log("setting new season: " + this.selectedSeasonId);
            this.$emit('reinit', this.league.id);
        },
        init() {
            this.selectedSeasonId = this.season.fmpSeasonId;
            this.scrollToActiveTab();
        },
        navigationClasses(p) {
            if (window.location.href.endsWith(p)) {
                return "tabs__item is-active";
            }
            return "tabs__item";
        },
        leagueSlug(league) {
            if (parseInt(league.name) == league.id) {
                return slugify(league.name);
            }
            return league.id + "--" + slugify(league.name);
        },
        scrollToActiveTab: function() {
            const tabs = document.getElementById('tabs');
            const activeTab = document.getElementsByClassName('is-active')[0];
            if (!activeTab) { return; }
            const offsetActiveTab = activeTab.offsetLeft;

            if (offsetActiveTab > window.innerWidth - 48) {
                tabs.scrollLeft = offsetActiveTab - 24;
            }
        }
    },
    computed: {
        availableSeasons() {
            if (!this.otherSeasons) { return []; }
            return this.otherSeasons.filter(s => parseInt(s.year) >= 2022);
        }
    },
}
</script>
