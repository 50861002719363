<template>
  <div v-if="baseUrl" class="home__slider">
    <Splide :options="options" :slides="slides">
      <SplideSlide v-for="slide in slides" v-bind:key="slide.id" @click="loadFeature(slide)">
        <div class="home__slider__text">
          <span>{{ competition_tags_text(slide) }}</span>
          <h1>{{ slide.feature.title }}</h1>
        </div>
        <div :class="slide_image_classes(slide)">
          <img :src="baseUrl + slide.feature_image" class="flex-img">
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';

export default {
    name: 'HomeSlider',
    components: {
        Splide,
        SplideSlide,
    },
    data() {
        return {
            options: {
                type: 'loop',
                arrows: false,
                autoplay: true,
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {},
        slide_image_classes(s) {
            if (s.image_placement == 1) {
                return "home__slider__image home__slider__image--aligntop";
            }
            else if (s.image_placement == 3) {
                return "home__slider__image home__slider__image--alignbottom";
            }
            return "home__slider__image home__slider__image--aligncenter";
        },
        competition_tags_text(feature) {
            if (feature && feature.feature_competition_tags) {
                return feature.feature_competition_tags.map(el => el.name).join(", ");
            }
            return "";
        },
        loadFeature(slide) {
            this.$router.push("/news/" + slide.feature_slug);
        }
    },
    props: {
        slides: {
            type: Array,
            default() { [] } 
        },
     },
     computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss">
  .home__slider {
    position: relative;
    background: $colorPrimary;
    color: #FFFFFF;
    height: 300px;
    margin-top: -104px;
    margin-bottom: 12px;

    @media (min-width: $breakpoint-sm) {
      height: 620px;
      margin-bottom: 28px;
    }

    &__image {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &--aligntop img {
        object-position: top;
      }

      &--aligncenter img {
        object-position: center;
      }

      &--alignbottom img {
        object-position: bottom;
      }

      &:before {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(135deg, #691A55 0%, #2B0722 100%);
        opacity: .56;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(180deg, rgba(0,0,0,0.00) 0%, #000000 67%);
        opacity: .32;
      }
    }

    &__text {
      position: relative;
      z-index: 6;
      display: flex;
      flex: 1;
      max-width: 640px;
      margin: auto auto 40px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-top: auto;
      cursor: pointer;

      @media (min-width: $breakpoint-sm) {
        margin: auto auto 54px;
      }

      h1 {
        color: $colorWhite;
        margin: 0;
      }

      span {
        font-size: 14px;
        font-weight: 600;
        color: rgba($colorWhite, .8);
        margin-bottom: 4px;

        @media (min-width: $breakpoint-sm) {
          font-size: 17px;
          margin-bottom: 6px;
        }
      }
    }

    .splide, .splide__track, .splide__list, .splide__slide, img {
      height: 100%;
    }

    .splide {
      margin: 0;

      &__track {
        margin: 0;
        padding: 0;
      }

      &__slide {
        display: flex;
      }

      &__pagination {
        z-index: 5;
        bottom: 16px;

        @media (min-width: $breakpoint-sm) {
          bottom: 28px;
        }

        li {
          display: flex;

          button {
            height: 4px;
            width: 4px;
            margin: 0 3px;
            background: $colorWhite;
            opacity: .48;

            @media (min-width: $breakpoint-sm) {
              height: 6px;
              width: 6px;
              margin: 0 4px;
            }

            &.is-active {
              height: 6px;
              width: 6px;
              margin: 0 2px;
              opacity: 1;
              transform: none;

              @media (min-width: $breakpoint-sm) {
                height: 8px;
                width: 8px;
                margin: 0 3px;
              }
            }
          }
        }
      }
    }
  }
</style>
