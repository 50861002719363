<template>
  <div class="hidden-sm-down spm-hbf-ad">
    <div class="extra-margin-bottom" :id="superBannerContainerId"></div>
  </div>
  <!-- Navigation -->
  <nav class="container">
    <div class="burger" @click="toggleBurger()">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <router-link to="/" class="logo" @click="closeBurger()"><img src="@/assets/logo.svg" alt="Logo HBF"></router-link>
    <router-link to="/">
      Home
    </router-link>
    <router-link to="/liga/1-bundesliga" class="nav-dropdown">
      1. Bundesliga
      <ul>
        <li>
          <router-link to="/news?filterLeague=1">
            News
          </router-link>
        </li>
        <li>
          <router-link to="/liga/1-bundesliga/spielplan">
            Spielplan
          </router-link>
        </li>
        <li>
          <router-link to="/liga/1-bundesliga/tabelle">
            Tabelle
          </router-link>
        </li>
        <li>
          <router-link to="/liga/1-bundesliga/statistiken">
            Statistiken
          </router-link>
        </li>
        <li>
          <router-link to="/liga/1-bundesliga/livestreams">
            Livestream
          </router-link>
        </li>
        <li>
          <router-link to="/liga/1-bundesliga/liveticker">
            Liveticker
          </router-link>
        </li>
        <li>
          <router-link to="/liga/1-bundesliga/ligakarte">
            Ligakarte
          </router-link>
        </li>
        <li>
          <router-link to="/info/spielerin-der-saison-hbf">
            Spielerin der Saison
          </router-link>
        </li>
      </ul>
    </router-link>
    <router-link to="/liga/2-bundesliga" class="nav-dropdown">
      2. Bundesliga
      <ul>
        <li>
          <router-link to="/news?filterLeague=2">
            News
          </router-link>
        </li>
        <li>
          <router-link to="/liga/2-bundesliga/spielplan">
            Spielplan
          </router-link>
        </li>
        <li>
          <router-link to="/liga/2-bundesliga/tabelle">
            Tabelle
          </router-link>
        </li>
        <li>
          <router-link to="/liga/2-bundesliga/statistiken">
            Statistiken
          </router-link>
        </li>
        <li>
          <router-link to="/liga/2-bundesliga/livestreams">
            Livestream
          </router-link>
        </li>
        <li>
          <router-link to="/liga/2-bundesliga/liveticker">
            Liveticker
          </router-link>
        </li>
        <li>
          <router-link to="/liga/2-bundesliga/ligakarte">
            Ligakarte
          </router-link>
        </li>
        <li>
          <router-link to="/info/spielerin-der-saison-hbf2">
            Spielerin der Saison
          </router-link>
        </li>
      </ul>
    </router-link>
    <router-link to="/liga/3--dhb-pokal" class="nav-dropdown">
      DHB-Pokal
      <ul>
        <li>
          <router-link to="/news?filterLeague=3">
            News
          </router-link>
        </li>
        <li>
          <router-link to="/liga/3--dhb-pokal/spielplan">
            Spielplan
          </router-link>
        </li>
        <li>
          <router-link to="/liga/3--dhb-pokal/livestreams">
            Livestream
          </router-link>
        </li>
        <li>
          <router-link to="/liga/3--dhb-pokal/liveticker">
            Liveticker
          </router-link>
        </li>
      </ul>
    </router-link>
    <router-link to="/info/final4" class="nav-dropdown">
      Final4
      <ul>
        <li>
          <router-link to="/info/final4-programm">
            Programm
          </router-link>
        </li>
        <li>
          <router-link to="/info/final4-tickets">
            Tickets
          </router-link>
        </li>
        <li>
          <router-link to="/info/final4-partner">
            Partner
          </router-link>
        </li>
        <li>
          <router-link to="/info/final4-arena-anfahrt">
            Arena &amp; Anfahrt
          </router-link>
        </li>
        <li>
          <router-link to="/info/final4-archiv">
            Archiv
          </router-link>
        </li>
      </ul>
    </router-link>
    <router-link to="/info/handball-super-cup" class="nav-dropdown">
      Super Cup
      <ul>
        <li>
          <router-link to="/match/84159/4">
            Handball Super Cup 2024
          </router-link>
        </li>
        <li>
          <router-link to="/info/programm">
            Programm
          </router-link>
        </li>
        <li>
          <router-link to="/info/tickets">
            Tickets
          </router-link>
        </li>
        <li>
          <router-link to="/info/arena-anfahrt">
            Arena &amp; Anfahrt
          </router-link>
        </li>
      </ul>
    </router-link>
    <router-link to="/info/hbf" class="nav-dropdown">
      HBF
      <ul>
        <li>
          <router-link to="/info/die-hbf-im-tv-stream">
            Die HBF im TV &amp; Stream
          </router-link>
        </li>
        <li>
          <router-link to="/info/newsletter">
            Newsletter
          </router-link>
        </li>
        <li>
          <router-link to="/info/partner">
            Partner
          </router-link>
        </li>
        <li>
          <router-link to="/info/gremien">
            Gremien
          </router-link>
        </li>
        <li>
          <router-link to="/info/ueber-uns">
            Geschäftsstelle
          </router-link>
        </li>
        <li>
          <router-link to="/info/hbf-award">
            HBF-Award
          </router-link>
        </li>
        <li>
          <router-link to="/info/nachwuchsarbeit">
            Nachwuchsarbeit
          </router-link>
        </li>
        <li>
          <router-link to="/info/prävention-gegen-gewalt">
            Prävention gegen Gewalt
          </router-link>
        </li>
        <li>
          <router-link to="/info/downloads">
            Downloads
          </router-link>
        </li>
        <li>
          <router-link to="/info/jobs">
            Jobs
          </router-link>
        </li>
        <li>
          <router-link to="/info/media">
            Media
          </router-link>
        </li>
      </ul>
    </router-link>
    <router-link to="/search" class="search">
      <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M8,0 C12.418278,0 16,3.581722 16,8 C16,9.69608103 15.4721883,11.268885 14.571857,12.56312 L17.5650575,15.5073196 C18.1449808,16.0775547 18.1449808,17.0020885 17.5650575,17.5723237 C17.0213793,18.1069191 16.1610152,18.1403313 15.5777512,17.6725603 L15.4649691,17.5723237 L12.475111,14.6322152 C11.1978642,15.4957323 9.6578211,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z"></path>
      </svg>
    </router-link>
  </nav>

  <!-- Content -->
  <router-view/>

  <!-- Advertisement (Skyscrapper) -->
  <div class="ad__skyscrapper">
    <div class="ad__skyscrapper__content">
      <!-- Ad Banner -->
      <DisplayAd adType="Sky"></DisplayAd>
    </div>
  </div>

  <div class="hidden-sm-up sticky-ad">
    <div :id="stickyAdContainerId"></div>
  </div>

  <!-- Footer -->
  <footer class="footer">
    <div class="container">
      <div class="footer__header">
        <router-link to="/">
          <img src="@/assets/logo.svg" alt="Logo HBF">
        </router-link>
        <div class="footer__poweredby">
          <div class="footer__poweredby__item">
            Portaltechnik by
            <a href="https://sportplatz-media.com" target="_blank">
              <img src="@/assets/logo-sportplatzmedia.svg" alt="Logo Sportplatzmedia">
            </a>
          </div>
          <div class="footer__poweredby__item">
            Offizieller Ballpartner
            <a href="https://www.derbystar.de/handball" target="_blank">
              <img src="@/assets/logo-select.svg" alt="Logo Derbystar Select">
            </a>
          </div>
        </div>
        <div class="footer__social">
          <a href="https://www.instagram.com/HandballBundesligaFrauen/" target="_blank" class="footer__social__item">
            <div class="footer__social__item__icon">
              <img src="@/assets/ic_32_social_instagram.svg" alt="Instagram">
            </div>
            Instagram
          </a>
          <a href="https://www.facebook.com/HandballBundesligaFrauen" target="_blank" class="footer__social__item">
            <div class="footer__social__item__icon">
              <img src="@/assets/ic_32_social_facebook.svg" alt="Facebook">
            </div>
            Facebook
          </a>
          <a href="https://de.linkedin.com/company/handball-bundesliga-frauen" target="_blank" class="footer__social__item">
            <div class="footer__social__item__icon">
              <img src="@/assets/ic_32_social_linkedin.svg" alt="Linkedin">
            </div>
            Linkedin
          </a>
          <a href="/info/newsletter" class="footer__social__item">
            <div class="footer__social__item__icon">
              <img src="@/assets/ic_32_social_newsletter.svg" alt="Newsletter">
            </div>
            Newsletter
          </a>
          <!-- <router-link to="" class="footer__social__item">
            <div class="footer__social__item__icon">
              <img src="@/assets/ic_32_social_podcast.svg" alt="Podcast">
            </div>
            Podcast
          </router-link> -->
          <a  href="https://hbf.ballco.shop/hbf-kollektion/" target="_blank" class="footer__social__item">
            <div class="footer__social__item__icon">
              <img src="@/assets/ic_32_social_shop.svg" alt="Shop">
            </div>
            Shop
          </a>
        </div>
      </div>
      <div class="footer__content">
        <ul>
          <li><router-link to="/liga/1-bundesliga">1. Bundesliga</router-link></li>
          <li><router-link to="/liga/1-bundesliga/spielplan">Spielplan</router-link></li>
          <li><router-link to="/liga/1-bundesliga/livestreams">Livestream</router-link></li>
        </ul>
        <ul>
          <li><router-link to="/liga/2-bundesliga">2. Bundesliga</router-link></li>
          <li><router-link to="/liga/2-bundesliga/spielplan">Spielplan</router-link></li>
          <li><router-link to="/liga/2-bundesliga/livestreams">Livestream</router-link></li>
        </ul>
        <ul>
          <li><router-link to="/liga/3--dhb-pokal">DHB-Pokal</router-link></li>
          <li><router-link to="/liga/3--dhb-pokal/spielplan">Spielplan</router-link></li>
          <li><router-link to="/liga/3--dhb-pokal/livestreams">Livestream</router-link></li>
        </ul>
        <ul>
          <li><router-link to="/info/final4">Final4</router-link></li>
          <li><router-link to="/info/final4-tickets">Tickets</router-link></li>
          <li><router-link to="/info/final4-partner">Partner</router-link></li>
          <!-- <li><router-link to="/info/final4-archiv">Archiv</router-link></li> -->
        </ul>
        <ul>
          <li><router-link to="#">HBF</router-link></li>
          <li><router-link to="/info/partner">Partner</router-link></li>
          <li><router-link to="/info/media">Media</router-link></li>
        </ul>
        <ul>
          <li><router-link to="/info/kontakt">Kontakt</router-link></li>
          <li><router-link to="/info/datenschutz">Datenschutz</router-link></li>
          <li><router-link to="/info/impressum">Impressum</router-link></li>
        </ul>
      </div>
    </div>
    <!--<div class="footer__logos">
        <div class="container">
          <div class="footer__logos__content">
            <router-link to="">
              <img src="@/assets/logo.svg" alt="">
            </router-link>
          </div>
        </div>
      </div>-->
  </footer>

  <div class="menu">
    <div class="menu__wrapper">
      <ul class="menu__list">

        <!-- Home -->
        <li @click="toggleBurger()" class="menu__item">
          <router-link to="/">Home</router-link>
        </li>

        <!-- 1. Bundesliga -->
        <li class="menu__item">

          <router-link to="/liga/1-bundesliga" @click="toggleBurger()">1. Bundesliga</router-link>

          <div class="menu__item__button" @click="toggleSubMenu"></div>

          <div class="menu__item__sub">
            <ul>
              <li>
                <router-link to="/news?filterLeague=1" @click="toggleBurger()">News</router-link>
              </li>
              <li>
                <router-link to="/liga/1-bundesliga/spielplan" @click="toggleBurger()">Spielplan</router-link>
              </li>
              <li>
                <router-link to="/liga/1-bundesliga/tabelle" @click="toggleBurger()">Tabelle</router-link>
              </li>
              <li>
                <router-link to="/liga/1-bundesliga/statistiken" @click="toggleBurger()">Statistiken</router-link>
              </li>
              <li>
                <router-link to="/liga/1-bundesliga/livestreams" @click="toggleBurger()">Livestreams</router-link>
              </li>
              <li>
                <router-link to="/liga/1-bundesliga/liveticker" @click="toggleBurger()">Liveticker</router-link>
              </li>
              <li>
                <router-link to="/info/spielerin-der-saison-hbf" @click="toggleBurger()">Spielerin der Saison</router-link>
              </li>
            </ul>
          </div>

        </li>

        <!-- 2. Bundesliga -->
        <li class="menu__item">
          <router-link to="/liga/2-bundesliga" @click="toggleBurger()">2. Bundesliga</router-link>
          <div class="menu__item__button" @click="toggleSubMenu"></div>
          <div class="menu__item__sub">
            <ul>
              <li>
                <router-link to="/news?filterLeague=2" @click="toggleBurger()">News</router-link>
              </li>
              <li>
                <router-link to="/liga/2-bundesliga/spielplan" @click="toggleBurger()">Spielplan</router-link>
              </li>
              <li>
                <router-link to="/liga/2-bundesliga/tabelle" @click="toggleBurger()">Tabelle</router-link>
              </li>
              <li>
                <router-link to="/liga/2-bundesliga/statistiken" @click="toggleBurger()">Statistiken</router-link>
              </li>
              <li>
                <router-link to="/liga/2-bundesliga/livestreams" @click="toggleBurger()">Livestreams</router-link>
              </li>
              <li>
                <router-link to="/liga/2-bundesliga/liveticker" @click="toggleBurger()">Liveticker</router-link>
              </li>
              <li>
                <router-link to="/info/spielerin-der-saison-hbf2" @click="toggleBurger()">Spielerin der Saison</router-link>
              </li>
            </ul>
          </div>
        </li>

        <!-- DHB-Pokal -->
        <li class="menu__item">
          <router-link to="/liga/3--dhb-pokal" @click="toggleBurger()">DHB-Pokal</router-link>
          <div class="menu__item__button" @click="toggleSubMenu"></div>
          <div class="menu__item__sub">
            <ul>
              <li>
                <router-link to="/news?filterLeague=3" @click="toggleBurger()">News</router-link>
              </li>
              <li>
                <router-link to="/liga/3--dhb-pokal" @click="toggleBurger()">Spielplan</router-link>
              </li>
              <li>
                <router-link to="/liga/3--dhb-pokal" @click="toggleBurger()">Livestreams</router-link>
              </li>
              <li>
                <router-link to="/liga/3--dhb-pokal" @click="toggleBurger()">Liveticker</router-link>
              </li>
            </ul>
          </div>
        </li>

        <!-- Final4 -->
        <li class="menu__item">
          <router-link to="/info/final4" @click="toggleBurger()">Final4</router-link>
          <div class="menu__item__button" @click="toggleSubMenu"></div>
          <div class="menu__item__sub">
            <ul>
              <li>
                <router-link to="/info/final4-programm" @click="toggleBurger()">Programm</router-link>
              </li>
              <li>
                <router-link to="/info/final4-tickets" @click="toggleBurger()">Tickets</router-link>
              </li>
              <li>
                <router-link to="/info/final4-partner" @click="toggleBurger()">Partner</router-link>
              </li>
              <li>
                <router-link to="/info/final4-arena-anfahrt" @click="toggleBurger()">Arena & Anfahrt</router-link>
              </li>
              <li>
                <router-link to="/info/final4-archiv" @click="toggleBurger()">Archiv</router-link>
              </li>
            </ul>
          </div>
        </li>

        <!-- Supee Cup -->
        <li class="menu__item">
          <router-link to="/info/handball-super-cup" @click="toggleBurger()">Super Cup</router-link>
          <div class="menu__item__button" @click="toggleSubMenu"></div>
          <div class="menu__item__sub">
            <ul>
              <li>
                <router-link to="/match/84159/4" @click="toggleBurger()">Handball Super Cup 2024</router-link>
              </li>
              <li>
                <router-link to="/info/programm" @click="toggleBurger()">Programm</router-link>
              </li>
              <li>
                <router-link to="/info/tickets" @click="toggleBurger()">Tickets</router-link>
              </li>
              <li>
                <router-link to="/info/arena-anfahrt" @click="toggleBurger()">Arena & Anfahrt</router-link>
              </li>
            </ul>
          </div>
        </li>

        <!-- HBF -->
        <li class="menu__item">
          <router-link to="/info/hbf" @click="toggleBurger()">HBF</router-link>
          <div class="menu__item__button" @click="toggleSubMenu"></div>
          <div class="menu__item__sub">
            <ul>
              <li>
                <router-link to="/info/die-hbf-im-tv-stream" @click="toggleBurger()">Die HBF im TV &amp; Stream</router-link>
              </li>
              <li>
                <router-link to="/info/newsletter" @click="toggleBurger()">Newsletter</router-link>
              </li>
              <li>
                <router-link to="/info/partner" @click="toggleBurger()">Partner</router-link>
              </li>
              <li>
                <router-link to="/info/gremien" @click="toggleBurger()">Gremien</router-link>
              </li>
              <li>
                <router-link to="/info/ueber-uns" @click="toggleBurger()">Geschäftsstelle</router-link>
              </li>
              <li>
                <router-link to="/info/hbf-award" @click="toggleBurger()">HBF-Award</router-link>
              </li>
              <li>
                <router-link to="/info/nachwuchsarbeit" @click="toggleBurger()">Nachwuchsarbeit</router-link>
              </li>
              <li>
                <router-link to="/info/prävention-gegen-gewalt" @click="toggleBurger()">Prävention gegen Gewalt</router-link>
              </li>
              <li>
                <router-link to="/info/downloads" @click="toggleBurger()">Downloads</router-link>
              </li>
              <li>
                <router-link to="/info/jobs" @click="toggleBurger()">Jobs</router-link>
              </li>
              <li>
                <router-link to="/info/media" @click="toggleBurger()">Media</router-link>
              </li>
            </ul>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import DisplayAd from "@/components/DisplayAd.vue";

let body = document.getElementsByTagName('body')[0];

export default {
    components: { DisplayAd },
    data () {
        return {
            burgerMenu: false,
            subMenu: false,
        }
    },
    computed: {
        superBannerContainerId() {
            if (this.$route.name == "home") {
                return "SPMADS_HBF-DISP_HOME_Sb";
            }
            return "SPMADS_HBF-DISP_REST_Sb";
        },
        stickyAdContainerId() {
            if (this.$route.name == "home") {
                return "SPMADS_HBF-MEW_HOME_Mobile_sticky";
            }
            return "SPMADS_HBF-MEW_REST_Mobile_sticky";     
        }
    },
    methods: {
        toggleBurger: function() {
            if (this.burgerMenu == false) {
                body.classList.add('nav-open');
                body.classList.remove('nav-close');
                this.burgerMenu = true;
            }
            else {
                body.classList.add('nav-close');
                body.classList.remove('nav-open');
                this.burgerMenu = false;
            }
        },
        closeBurger: function() {
            if (this.burgerMenu == true) {
                body.classList.add('nav-close');
                body.classList.remove('nav-open');
            }
        },
        toggleSubMenu(event) {
            if (event.target.className == "menu__item__button") {
                event.target.className = 'menu__item__button menu__item__button--open';
                event.target.parentElement.className = 'menu__item menu__item--open';
            } else {
                event.target.className = 'menu__item__button';
                event.target.parentElement.className = 'menu__item';
            }
        }
    }
}
</script>

<style lang="scss">
// Global Styles
@import "@/scss/_global.scss";
// Grid System
@import "@/scss/_reflex.scss";
</style>

<style lang="scss">
.sticky-ad {
    position: sticky;
    bottom: 0;
    text-align: center;
}
.spm-hbf-ad {
    text-align: center;
}
.extra-margin-bottom {
    margin-bottom: 10px;
}
.extra-margin-bottom:empty {
    margin: 0;
}

nav {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  padding: 12px 0;

  @media (min-width: $breakpoint-sm) {
    padding: 20px 0;
  }

  .nav-open & {
    z-index: 100001;
  }

  a {
    position: relative;
    font-size: 17px;
    font-weight: 700;
    color: $colorBlack;
    text-decoration: none;
    margin: 0 8px;

    @media (min-width: $breakpoint-lg) {
      margin: 0 12px;
    }

    .home &, .league & {
      color: $colorWhite !important;
    }

    &.router-link-exact-active {
      color: $colorPrimary;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: -3px;
      width: 100%;
      height: 2px;
      background: $colorBlack;
      opacity: 0;
      transition: all .2s ease-in-out;

      .home &, .league & {
        background: $colorWhite !important;
      }
    }

    &:not(.logo):not(.search):not(.burger) {
      display: none;
      padding: 12px 0;

      @media (min-width: $breakpoint-sm) {
        display: block;
      }

      &.router-link-exact-active {
        &:before {
          opacity: 1;
          bottom: 9px;
          background: $colorPrimary;
        }
      }
    }

    &.logo {
      margin: 0;
      margin-right: auto;

      img {
        height: 56px;

        @media (min-width: $breakpoint-sm) {
          height: 64px;
        }
      }
    }

    &.search {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      transition: all .2s ease-in-out;
      transform: translateX(11px);

      svg path {
        fill: $colorBlack;

        .home &, .league & {
          fill: $colorWhite;
        }
      }

      @media (min-width: $breakpoint-sm) {
        margin-left: auto;
        border-radius: 50%;
        transform: translateX(0);
        background: rgba($colorBlack, .12);

        .home &, .league & {
          background: rgba($colorWhite, .12);
        }

        &:hover {
          background: rgba($colorBlack, .2);

          .home &, .league & {
            background: rgba($colorWhite, .2);
          }
        }
      }
    }

    img {
      display: flex;
    }

    &.nav-dropdown {
      ul {
        opacity: 0;
        position: absolute;
        top: 35px;
        left: 50%;
        min-width: 256px;
        transform: translateX(-50%) translateY(12px);
        background: $colorWhite;
        padding: 0;
        border-radius: 8px;
        background: $colorWhite;
        box-shadow: 0 0 0 1px rgba($colorBlack, .06), 0 2px 8px 0 rgba($colorBlack,0.02);
        list-style-type: none;
        pointer-events: none;
        transition: all .2s ease-in-out;

        &:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          left: 50%;
          top: -6px;
          transform: translateX(-50%) rotate(45deg);
          background: $colorWhite;
          box-shadow: 0 0 0 1px rgba($colorBlack, .06), 0 2px 8px 0 rgba($colorBlack,0.02);
        }

        &:after {
          content: '';
          position: absolute;
          width: 20px;
          height: 10px;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          background: $colorWhite;
        }

        li {
          position: relative;
          box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .04);
          transition: all .2s ease-in-out;

          &:first-child {
            padding-top: 4px;
          }

          &:last-child {
            padding-bottom: 4px;
          }

          &:before {
            opacity: 0;
            position: absolute;
            content: '';
            right: 32px;
            top: 50%;
            width: 16px;
            height: 16px;
            transform: translateY(-50%);
            background: url('@/assets/ic_16_arrow.svg');
            transition: all .2s ease-in-out;
          }

          &:hover {
            background: rgba($colorBlack, .02);

            &:before {
              opacity: .4;
              right: 16px;
            }
          }
        }

        a {
          font-size: 14px;
          color: $colorBlack !important;
          padding: 9px 16px !important;
          margin: 0;

          &:before {
            display: none;
          }
        }
      }

      &:hover {
        ul {
          opacity: 1;
          transform: translateX(-50%) translateY(0);
          z-index: 999;
          pointer-events: all;
        }
      }
    }
  }

  .burger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: auto;
    width: 40px;
    height: 40px;
    margin-left: 0;
    transform: translateX(-11px);

    span {
      display: flex;
      width: 18px;
      height: 2px;
      background: $colorBlack;
      border-radius: 1px;
      transition: all .2s ease-in-out;

      .home &, .league & {
        background: $colorWhite;
      }

      &:first-child {
        opacity: 1;
        margin-bottom: 4px;
      }

      &:last-child {
        opacity: 1;
        margin-top: 4px;
      }

      &:nth-child(3) {
        margin-top: -2px;
      }

      .nav-open & {
        background: $colorBlack;

        &:nth-child(2) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }

        &:first-child {
          opacity: 0;
          transform: translateY(-8px);
        }

        &:last-child {
          opacity: 0;
          transform: translateY(8px);
        }
      }
    }

    @media (min-width: $breakpoint-sm) {
      display: none;
    }
  }
}

@keyframes openMenu {
  from {transform: translateX(-100vw);}
  to {transform: translateX(0);}
}

@keyframes closeMenu {
  from {transform: translateX(0);}
  to {transform: translateX(-100vw);}
}

.menu {
  display: flex;
  position: fixed;
  z-index: 999;
  top: 0;
  background: $colorWhite;
  width: 100vw;
  height: 100vh;
  transform: translateX(-100vw);
  
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 96px;
    background-image: linear-gradient(180deg, $colorWhite 0%, $colorWhite 68%, rgba($colorWhite, 0) 100%);
  }

  .nav-open & {
    animation: openMenu .4s ease-in-out forwards;
  }

  .nav-close & {
    animation: closeMenu .4s ease-in-out forwards;
  }

  &__wrapper {
    flex: 1;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  &__list {
    flex: 1;
    padding: 0;
    list-style-type: none;

    .menu__item {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      font-size: 17px;
      font-weight: 700;
      width: 100%;
      transition: all .2s ease-in-out;

      &:not(:last-child) {
        box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);
      }

      &:after {
        position: absolute;
        z-index: -1;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba($colorBlack, .04);
        opacity: 0;
        transition: all .2s ease-in-out;
      }

      &--open {
        &:after {
          opacity: 1;
        }
      }

      &--open {
        > a {
          &.router-link-exact-active {
            color: $colorBlack !important;

            &:before {
              display: none;
            }
          }
        }
      }

      a {
        position: relative;
        display: flex;
        color: $colorBlack;
        text-decoration: none;
        width: calc(100% - 56px);
        height: 100%;
        padding: 12px 24px;

        &.router-link-exact-active {
          color: $colorPrimary;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: calc(50% - 1px);
            width: 16px;
            height: 2px;
            background: $colorPrimary;
          }
        }
      }

      &__button {
        position: relative;
        width: 56px;
        height: 52px;
        opacity: .48;
        transition: all .2s ease-in-out;

        &:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 2px;
          top: calc(50% - 1px);
          left: calc(50% - 6px);
          background: $colorBlack;
          transition: all .2s ease-in-out;
        }

        &:after {
          content: '';
          position: absolute;
          width: 2px;
          height: 12px;
          top: calc(50% - 6px);
          left: calc(50% - 1px);
          background: $colorBlack;
          transition: all .2s ease-in-out;
        }

        &--open {
          opacity: 1;

          + .menu__item__sub {
            display: flex;
          }

          &:before {
            transform: rotate(90deg);
            transform-origin: center center;
            opacity: 0;
          }

          &:after {
            transform: rotate(90deg);
            transform-origin: center center;
          }
        }
      }

      
      &__sub {
        display: none;
        width: 100%;

        ul {
          width: 100%;
          padding: 0 24px;
          list-style-type: none;
          margin-bottom: 12px;

          li {
            width: 100%;
            font-size: 14px;
            font-weight: 700;

            &:not(:last-child) {
              box-shadow: inset 0 -1px 0 0 rgba($colorBlack, .08);
            }

            a {
              color: rgba($colorBlack, .8);
              width: 100%;
              padding: 9px 0;

              &.router-link-exact-active {
                &:before {
                  left: -24px;
                }
              }
            }
          }
        }
      }
      
    }
  }
}

.footer {
  margin-top: auto;
  background: $colorPrimary;
  margin-top: 64px;

  @media (min-width: $breakpoint-sm) {
    margin-top: 128px;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
    box-shadow: inset 0 -1px 0 0 rgba($colorWhite, .08);

    @media (min-width: $breakpoint-sm) {
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    padding: 24px 0;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-sm) {
      padding: 40px 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      flex: 0 50%;

      @media (min-width: $breakpoint-sm) {
        flex: 0 33.3%;
      }

      @media (min-width: $breakpoint-md) {
        flex: 1;    

        &:not(:last-child) {
          margin-right: 64px;
        }   
      }

      li {
        font-size: 14px;
        line-height: 14px;
        font-weight: 300;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:first-child {
          font-size: 11px;
          line-height: 11px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
          opacity: .48;
          transition: all .2s ease-in-out;

          &:hover {
            opacity: 1;
          }
        }

        a {
          text-decoration: none;
          color: $colorWhite;
        }
      }
    }
  }

  &__logos {
    background: $colorWhite;
    padding: 32px 0;

    &__content {
      display: flex;
      justify-content: space-around;
    }

    img {
      filter: saturate(0);
      opacity: .48;
      transition: all .2s ease-in-out;
      max-height: 48px;

      &:hover {
        opacity: 1;
        filter: saturate(1);
      }
    }
  }

  &__poweredby {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-sm) {
      flex-direction: row;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 11px;
      font-weight: 700;
      color: rgba($colorWhite, .48);
      padding: 12px 0;

      &:first-child {
        box-shadow: inset 0 -1px 0 0 rgba($colorWhite, .24);

        @media (min-width: $breakpoint-sm) {
          box-shadow: none;
        }
      }

      @media (min-width: $breakpoint-sm) {
        align-items: flex-start;
        margin-left: 32px;
        margin-top: 0;
      }

      img {
        opacity: .48;
        transition: all .2s ease-in-out;
        margin-top: 5px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__social {
    display: flex;
    margin-top: 27px;

    @media (min-width: $breakpoint-sm) {
      margin-left: auto;
      margin-top: 0;
    }

    &__item {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 11px;
      font-weight: 600;
      color: rgba($colorWhite, .48);
      text-transform: uppercase;
      text-decoration: none;
      min-width: 64px;
      transition: all .2s ease-in-out;

      &:not(:last-child) {
        margin-right: 8px;

        @media (min-width: $breakpoint-sm) {
          margin-right: 12px;
        }
      }

      &:hover {
        color: $colorWhite;

        .footer__social__item__icon {
          background: rgba($colorWhite, .12);
        }
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        background: rgba($colorWhite, .08);
        border-radius: 50%;
        margin-bottom: 8px;
        transition: all .2s ease-in-out;
      }
    }
  }
}
</style>
