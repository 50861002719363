<template>
  <div v-if="playerStatistics && playerStatistics.length > 0">
    <!-- MOBILE -->
    <div class="slider hidden-sm-up">
      <Splide :options="options">
        <SplideSlide v-if="topScorers">
          <PlayerStatisticsTile :data="topScorers" :competitionId="competitionId" mainKey="goals" mainLabel="Tore"></PlayerStatisticsTile>
        </SplideSlide>
        <SplideSlide>
          <PlayerStatisticsTile :data="topSaves" :statisticsType="'topSaves'" :competitionId="competitionId" mainKey="saves" mainLabel="Paraden"></PlayerStatisticsTile>
        </SplideSlide>
        <SplideSlide v-if="medRecBanner">
          <div class="list">
            <a v-if="medRecBanner.banner_target == 2" target="_blank" :href="medRecBanner.link"><img class="banner_image" :src="baseUrl + medRecBanner.image.meta.download_url" alt="" /></a>
            <a v-else :href="medRecBanner.link"><img class="banner_image" :src="baseUrl + medRecBanner.image.meta.download_url" alt="" /></a>
          </div>
        </SplideSlide>
      </Splide>
    </div>

    <!-- Desktop -->
    <div class="grid hidden-xs-down">
      <div v-if="topScorers" class="col-md-4 col-sm-6">
        <PlayerStatisticsTile :data="topScorers" :competitionId="competitionId" mainKey="goals" mainLabel="Tore"></PlayerStatisticsTile>
      </div>
      <div v-if="topScorers" class="col-md-4 col-sm-6">
        <PlayerStatisticsTile :data="topSaves" :statisticsType="'topSaves'" :competitionId="competitionId" mainKey="saves" mainLabel="Paraden"></PlayerStatisticsTile>
      </div>

      <div v-if="medRecBanner" class="col-md-4 hidden-sm-down">
        <div class="list">
            <a v-if="medRecBanner.banner_target == 2" :href="medRecBanner.link"><img class="banner_image" :src="baseUrl + medRecBanner.image.meta.download_url" alt="" /></a>
            <a v-else :href="medRecBanner.link"><img class="banner_image" :src="baseUrl + medRecBanner.image.meta.download_url" alt="" /></a>
        </div>
      </div>
    </div>

  </div>
  <div v-else class="grid">
    <div class="col-md-8 col-sm-8">
      <StatisticsEmpty v-if="teamId != -1" description="Das erste Saisonspiel des Teams steht noch bevor." />
      <StatisticsEmpty v-else />
    </div>

    <div v-if="medRecBanner" class="col-md-4 col-sm-4">
        <div class="list">
            <a v-if="medRecBanner.banner_target == 2" :href="medRecBanner.link"><img class="banner_image" :src="baseUrl + medRecBanner.image.meta.download_url" alt="" /></a>
            <a v-else :href="medRecBanner.link"><img class="banner_image" :src="baseUrl + medRecBanner.image.meta.download_url" alt="" /></a>
        </div>
    </div>
  </div>

</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import PlayerStatisticsTile from '@/components/PlayerStatisticsTile.vue'
import StatisticsEmpty from './StatisticsEmpty.vue';

export default {
    name: 'HomeStatistics',
    components: {
        Splide,
        SplideSlide,
        PlayerStatisticsTile,
        StatisticsEmpty,
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$store.dispatch("fetchAdBanners").then( () => {
                this.adbanners = this.$store.getters.getAdBanners;
            });

            this.$store.dispatch("fetchData",  {filename: "players/players_" + this.seasonId + ".json"}).then( (res) => {
                this.playerStatistics = res.playerStatistics;
                if (!this.playerStatistics) { return; }

                if (this.teamId != -1) {
                    this.playerStatistics = this.playerStatistics.filter(s => s.fmpTeamId == this.teamId);
                }
                this.playerStatistics.forEach(s => {
                    s.avgGoals = s.avgGoals.toFixed(1);
                    if (s.totalMatches > 0) { s._avgAssists = (s.assists / s.totalMatches).toFixed(1); }
                });
                this.topScorers = [...this.playerStatistics.sort((s1, s2) => s2.goals - s1.goals).slice(0, 5)];
                this.topSaves = [...this.playerStatistics.filter(s => s.saves > 0).sort((s1, s2) => s2.saves - s1.saves).slice(0, 5)];
            });
        }
    },
    computed: {
        medRecBanner() {
            if (this.adbanners) {
                let ads = this.adbanners.filter(ab => ab.banner_position == 1 && ab.is_active == true);
                console.log("found ads "  + ads.length);
                if (ads.length > 0) {
                    var idx = Math.floor(Math.random() * ads.length);
                    return ads[idx];
                }
            }
            return null;
        },
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    },
    props: {
        seasonId: {
            type: Number,
            default() { return -1; } 
        },
        teamId: {
            type: Number,
            default() { return -1; } 
        },
        competitionId: {
            type: Number,
            default() { return -1; } 
        },
    },
    data() {
        return {
            options: {
                arrows: false,
                pagination: false,
                gap: '8px',
                padding: '24px'
            },
            playerStatistics: null,
            topScorers: null,
            topSaves: null,
            adbanners: null,
        };
    },
}
</script>

<style lang="scss">
    .list {
        width: 100%;
        height: 100%;

        a {
            display: flex;
            width: 100%;
            height: 100%;

            .banner_image {
                max-width: 100%;
                margin: auto;
            }
        }
    }
</style>