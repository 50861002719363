<template>
  <router-link :to="'/news/' + article.meta.slug" class="news-card" :class="horizontal ? 'news-card--horizontal' : ''">

    <img v-if="article.image && !useSquarePreviewImage" class="news-card__image" :src="baseUrl + article.preview_image_url" alt="">
    <img v-else-if="article.image && useSquarePreviewImage" class="news-card__image" :src="baseUrl + article.square_preview_image_url" alt="">

    <div class="news-card__text">
      <span>{{ articleDate }}</span>
      <h3>{{ article.title }}</h3>
      <p>{{ article.intro }}</p>
    </div>

    <a v-if="article.meta" :href="'/news/' + article.meta.slug" class="news-card__more hidden-xs-down">
      Weiterlesen
    </a>

  </router-link>
</template>

<script>
import moment from 'moment';

export default {
    name: 'NewsCard',
    data() {
        return {
            articleDate: null,
        };
    },
    props: {
        article: {
            type: Object,
            default() { return {}; } 
        },
        horizontal: {
          type: Boolean,
          default() { return false; }
        },
        // TODO / FIXME: useSquarePreviewImage and horizontal can probably be merged into one
        useSquarePreviewImage: {
            type: Boolean,
            default() { return false; }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.articleDate = moment(this.article.go_live_at || this.article.meta.first_published_at).locale("de").format('dddd, D. MMMM');
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        }
    }
}
</script>

<style lang="scss">
  .news-card {
    display: flex;
    background: $colorWhite;
    box-shadow: 0 2px 8px 0 rgba($colorBlack, .02), 0 0 0 1px rgba($colorBlack, .06);
    text-decoration: none;
    border-radius: 12px;
    padding: 16px;
    overflow: hidden;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      box-shadow: 0 8px 8px 0 rgba($colorBlack, .04), 0 0 0 1px rgba($colorBlack, .06);
    }

    @media (min-width: $breakpoint-sm) {
      flex-direction: column;

      &--horizontal {
        flex-direction: row;
        align-items: center;
      }
    }

    &__image {
      width: 80px;
      height: auto;
      object-fit: cover;
      border-radius: 4px;
      margin-right: 16px;

      .news-card--horizontal & {
        width: 96px;
        margin-right: 0;
      }

      @media (min-width: $breakpoint-sm) {
        flex-direction: column;
        width: 100%;
        height: 140px;
        margin-right: 0;

        .news-card--horizontal & {
          width: 160px;
        }
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      margin-top: 4px;
      margin-bottom: auto;

      @media (min-width: $breakpoint-sm) {
        margin-top: 16px;
      }

      .news-card--horizontal & {
        margin-top: 0;
        margin-left: 16px;
        margin-bottom: 0;
      }

      span {
        font-size: 10px;
        font-weight: 800;
        color: rgba($colorBlack, .48);
        text-transform: uppercase;
      }

      h3 {
        font-weight: 700;
        margin: 3px 0 0 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;

        @media (min-width: $breakpoint-sm) {
          margin: 6px 0 0 0;
        }
      }

      p {
        font-size: 11px;
        line-height: 16px;
        color: rgba($colorBlack, .64);
        margin: 4px 0 4px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        max-height: 32px;

        @media (min-width: $breakpoint-sm) {
          font-size: 14px;
          line-height: 20px;
          margin: 6px 0 0 0;
          -webkit-line-clamp: 3;
          max-height: inherit;
        }
      }
    }

    &__more {
      font-size: 14px;
      font-weight: 700;
      color: $colorPrimary;
      text-decoration: none;
      margin: 14px 0 2px;

      .news-card--horizontal & {
        display: none;
      }

      &:after {
        content: url('@/assets/ic_12_arrow_brand.svg');
        margin-left: 4px;
      }
    }
  }
</style>
