<template>

  <div class="rankingPreview" v-bind:class="[(competitionId == 1 ? 'rankingPreview--liga1' : ''), (competitionId == 2 ? 'rankingPreview--liga2' : '')]">

    <router-link :to="'/team/' + teamSlug(t) + '/' + competitionId" v-for="t in table" v-bind:key="t.fmpTeamId" :class="currentlyPlayingTeams.indexOf(t.fmpTeamId) != -1 ? 'rankingPreview__item rankingPreview__item--live' : 'rankingPreview__item'">
      <div class="rankingPreview__item__position">
        {{ t.position }}
      </div>
      <div class="rankingPreview__item__logo">
        <img :src="teamLogo(t)" :alt="'Logo ' + t.teamName">
      </div>
      <div class="rankingPreview__item__name">
        {{ t.teamName }}
      </div>
      <div v-if="t.lastMatches" class="rankingPreview__item__form">
        <span v-for="o in t.lastMatches.slice().reverse()" v-bind:key="o.fmpMatchId" :class="_matchResultClass(o, t)"></span>
        <span v-for="x in Array(5-t.lastMatches.length).keys()" v-bind:key="x" class="placeholder"></span>
      </div>
      <div class="rankingPreview__item__points">
        {{ t.points }}
      </div>
    </router-link>

    <div @click="showAllTeams" class="rankingPreview__button">
      Alle Teams anzeigen
    </div>

  </div>

</template>

<script>
import {teamLogoUrl, matchResultClass, slugify} from '@/hbf-helper';

export default {
    name: 'RankingPreviewLeague',
    mounted() {
          this.init();
     },
     data() {
          return {
               table: null,
               liveData: null,
          }
     },
     methods: {
        init() {
            this.$store.dispatch("fetchLiveData").then( () => {
                this.liveData = this.$store.getters.getLiveData;
            });
            this.$store.dispatch("fetchData",  {filename: "tables/table_" + this.seasonId + ".json"}).then( (res) => {
                this.table = res.table;
            });
        },
        showAllTeams() {
            document.querySelectorAll('.rankingPreview__item').forEach(function(el) {
                el.style.display="flex";
            });
            document.querySelectorAll('.rankingPreview__button').forEach(function(el) {
                el.style.display="none";
            });
        },
        teamLogo(team) {
            return teamLogoUrl(team, 40, this.baseUrl, this.seasonId);
        },
        _matchResultClass(o, team) {
            return matchResultClass(o ,team);
        },
        teamSlug(team) {
            return team.fmpTeamId + "--" + slugify(team.teamName);
        }
    },
    props: {
        competitionId: {
            type: Number,
            default() { return -1; } 
        },
        seasonId: {
            type: Number,
            default() { return -1; } 
        }
    },
    computed: {
        baseUrl() {
            return this.$store.getters.getBaseUrl;
        },
        currentlyPlayingTeams() {
            if (this.liveData) {
                let matches = this.liveData.filter(m => m.fmpSeasonId == this.seasonId && m.match_time > 0 && m.status != "closed");
                return matches.flatMap(m => [m.fmpTeamHomeId, m.fmpTeamAwayId]);
            }
            return [];
        }
    }
}
</script>

<style lang="scss">
    @media (min-width: $breakpoint-sm) {
        .rankingPreview--liga1 {
            .rankingPreview__item {
                margin: .9px 0;
            }
        }
        .rankingPreview--liga2 {
            .rankingPreview__item {
                padding: 3.43px 5px;
            }
        }
    }
</style>
